@import "../../theme/shared.scss";

.mb-email-dialog {
  --height: auto;
  --width: auto;
  --max-width: 500px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  .modal-wrapper {
    max-width: 100%;
    width: 400px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .modal-content {
        background: var(--ion-color-tertiary);
      }
    }
  }
  .mb-email-dialog-children-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    .mb-email-dialog-title-label {
      margin-top: 15px;
      margin-bottom: 15px;
      text-align: center;
    }
    .mb-email-dialog-message-label {
      text-align: center;
    }

    .mb-email-dialog-dates-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .mb-email-dialog-options-container {
      display: flex;
      padding-top: 30px;
      justify-content: space-between;
      width: 100%;
      .mb-email-dialog-no-button {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--ion-color-medium);
        height: 30px;
        width: 100px;
      }
      .mb-email-dialog-submit-button {
        height: 30px;
        width: 100px;
      }
    }
    .mb-email-dialog-action-button {
      height: 30px;
      width: 98px;
      margin-top: 55px;
    }
  }
}
