.patient-account-setup-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .patient-account-setup-form-description-container {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    &.mobile {
      padding: 0px !important;
    }
  }
  .patient-account-setup-form-container-grid {
    width: 100%;
    margin-top: 30px;
    .patient-account-row-dropdown {
      padding: 0px 15px;
    }
    .patient-account-setup-form-container-col {
      padding: 0 15px 30px 15px;
      &.has-file-input {
        padding: 0px 0px 30px 0px !important;
      }
      &.has-dropdown-input {
        padding: 0px 0px 30px 0px !important;
      }
      &.mobile {
        padding: 0px 0px 30px 0px !important;
      }
      &.last {
        padding-bottom: 25px;
      }
      .patient-account-setup-dropdown-gender {
        margin-left: 15px;
        margin-right: 15px;
        width: 100%;
      }
      .patient-account-setup-province-container {
        width: 100%;
      }
      .patient-birthday-input {
        border-bottom: 1px solid var(--ion-color-medium);
      }
    }
  }
  .patient-account-setup-form-footer-description-container {
    display: flex;
    flex-direction: column;
    padding: 24px 15px 0 15px;
    background-color: rgba(87, 185, 198, 0.1);
    border-radius: 4px;
    .patient-account-setup-data-privacy-consent-details {
      margin-top: 20px;
      margin-bottom: 20px;
      .patient-account-setup-data-privacy-consent-item {
        display: flex;
        height: 23px;
        margin-bottom: 17px;
        .patient-account-setup-data-privacy-consent-checkbox {
          margin-right: 17px;
          --border-color: var(--ion-color-medium);
          --size: 23px;
        }
        .patient-account-setup-data-privacy-consent-label {
          text-align: start;
        }
        .patient-account-setup-covid-consent-label {
          text-align: start;
          cursor: pointer;
        }
      }
    }
  }
}

a {
  cursor: pointer;
  color: #1b91ff !important;
}
