.faq-card {
  width: 850px;
  padding: 25px;
  .faq-card-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .faq-header-label {
      margin-bottom: 5px;
    }
    .faq-header-description {
      margin-bottom: 14px;
    }
  }
  .faq-answers-container {
    padding: 15px;
  }
}

@media (max-width: 414px) {
  .faq-card {
    width: 100%;
    padding: 0;

    .faq-item {
      .faq-question-label {
        font-size: 13px !important;
      }
    }
  }
}
