.verify-email-card {
  height: 200px;
  width: 300px;
  .verify-email-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    .verify-email-icon {
      height: 50px;
      width: 50px;
      margin-bottom: 10px;
      &.success {
        background: url("../../assets/icons/success.svg") no-repeat center
          center;
      }
      &.warning {
        background: url("../../assets/icons/warning.svg") no-repeat center
          center;
      }
    }
    .verify-email-ok-button {
      height: 30px;
      width: 90px;
      margin-top: 20px;
    }
  }
}
