.hospital-department-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  .hospital-department-select-list {
    .hospital-department-select {
      width: 100%;
      max-width: 300px;
      padding: 0px 10px;
    }
  }
}
