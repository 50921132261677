@import "../../theme/shared.scss";

.mb-appointment-details-dialog {
  --height: auto;
  --width: auto;
  --max-width: 500px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  .modal-wrapper {
    max-width: 100%;
    width: 500px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .mb-appointment-details-dialog-children-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 30px;
    justify-content: flex-start;
    // .mb-appointment-details-dialog-title-label {
    // margin-top: 15px;
    // margin-bottom: 15px;
    // }
    .mb-appointment-details-dialog-message-label {
      text-align: flex-start;
    }
  }
  .mb-appointment-details-dialog-children-content {
    margin: 30px;
  }
  .mb-appointment-details-dialog-buttons-container {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
    &.mobile {
      padding: 20px 30px;
    }
    .mb-appointment-details-dialog-back-edit-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .mb-appointment-details-dialog-no-button {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--ion-color-primary);
        height: 30px;
        width: 100px;
      }
      .mb-appointment-details-dialog-buttons-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        justify-content: flex-end;
        .mb-appointment-details-dialog-submit-button {
          height: 30px;
          width: 100px;
          filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
          &.clear-search {
            margin-right: 10px;
            filter: none !important;
          }
        }
      }
    }
  }
  .mb-appointment-details-dialog-mobile-submit-button {
    height: 30px;
    width: 100%;
    margin-bottom: 8px;
  }
  .mb-appointment-details-dialog-mobile-no-button {
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-color-medium);
    height: 30px !important;
    width: 100%;
  }
  .mb-appointment-details-dialog-mobile-cancel-button {
    margin-top: 8px;
    height: 20px;
  }
}
