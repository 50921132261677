.hospital-applications-search-input-container {
  margin-bottom: 42px;
  .hospital-applications-search-input {
    --border-radius: 0;
    --background: var(--ion-color-secondary);
    --icon-color: var(--ion-color-tertiary);
    border: 1px solid var(--ion-color-medium);
    height: 37px;
  }
}

.hospital-applications-table-header-grid {
  background: var(--ion-color-medium);
  .hospital-applications-table-header-row {
    .hospital-applications-table-header-col {
      padding: 10px;
    }
  }
}

.hospital-applications-slides-container {
  overflow-x: hidden !important;
  .hospital-application-list-grid {
    border: 1px solid var(--ion-color-medium);
    margin-bottom: 23px;
    .hospital-application-list-row {
      border-bottom: 1px solid var(--ion-color-medium);

      &.last {
        border-bottom: 0;
      }

      .hospital-application-list-col {
        padding: 11px;
        overflow: hidden;
        align-self: center;
        text-overflow: ellipsis;
        ion-label {
          white-space: nowrap;
        }
      }
    }
    .hospital-application-view-button {
      height: 24px;
    }
  }
}

.hospital-application-list-no-schedule-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  .hospital-application-list-no-schedule-container {
    margin-top: 13px;
    align-self: center;
  }
}
.hospital-application-list-pagination-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .hospital-application-list-previous-icon {
    background: url(../../assets/icons/previous-icon.svg) no-repeat center;
    cursor: pointer;
    margin-right: 10px;
  }
  .hospital-application-list-next-icon {
    background: url(../../assets/icons/next-icon.svg) no-repeat center;
    cursor: pointer;
    margin-left: 10px;
  }
}

.mb-application-details-dialog {
  --height: auto;
  --width: auto;
  --max-width: 600px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  .modal-wrapper {
    max-width: 100%;
    width: 600px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
