@import "../../theme/shared.scss";

.mb-view-appointment-container {
  .mb-view-appointment-status-card {
    min-height: 123px;
    height: 100%;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1) !important;
    max-width: 769px;
    width: 100%;
    .mb-view-appointment-status-card-content-container {
      height: 100%;
      min-height: 123px;
      .mb-view-appointment-status-grid {
        height: 100%;
        min-height: 123px;
        .mb-view-appointment-status-row {
          height: 100%;
          min-height: 123px;
          .mb-view-appointment-status-status-col {
            height: 100%;
            min-height: 123px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .first-status-container {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .first-status-icon {
                height: 31px;
                width: 31px;
                &.label {
                  padding-top: 5px;
                  text-align: center;
                  border-radius: 50%;
                  background-color: var(--ion-color-primary);
                }
                &.cancelled {
                  padding-top: 5px;
                  text-align: center;
                  border-radius: 50%;
                  background-color: var(--ion-color-danger);
                }
              }
            }
            .second-status-container {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .second-status-icon {
                height: 31px;
                width: 31px;
                &.label {
                  padding-top: 5px;
                  text-align: center;
                  border-radius: 50%;
                  background-color: var(--ion-color-primary);
                }
              }
            }
            .third-status-container {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .third-status-icon {
                height: 31px;
                width: 31px;
                &.label {
                  padding-top: 5px;
                  text-align: center;
                  border-radius: 50%;
                  background-color: var(--ion-color-secondary);
                }
              }
            }
          }
          .mb-view-appointment-status-divider-col {
            width: 100%;
            .status-divider-container {
              width: 100%;
              height: 100%;
              min-height: 123px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .mb-view-appointment-detail-card {
    margin-top: 20px;
    min-height: 545px;
    height: 100%;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1) !important;
    max-width: 769px;
    width: 100%;
    .mb-view-appointment-detail-card-container {
      padding: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid var(--ion-color-medium);
    }
    .mb-view-appointment-additional-detail-card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 60px;
      .additional-header-label {
        text-align: start;
        width: 100%;
      }
      .additional-detail-grid {
        .additional-detail-row {
          .additional-detail-col-label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .additional-input-label {
              padding-top: 21px;
              &.qr-code {
                height: 220px;
              }
            }
          }
          .additional-detail-col-value {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .additional-input-value {
              padding-top: 20px;
              &.qr-code {
                display: flex;
                flex-direction: column;
              }
              .view-qr-button {
                height: 34px;
                margin-top: 15px;
                border: 1px solid var(--ion-color-primary);
                border-radius: 4px;
              }
            }

            .additional-details-remarks-container {
              padding-top: 23px;
              width: 100%;
              .additional-details-remarks-grid {
                .additional-details-remarks-row {
                  .additional-details-remarks-timeline-col {
                    .remarks-timeline-container {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      .remarks-icon {
                        height: 11px;
                        width: 11px;
                        border-radius: 50%;
                        background-color: var(--ion-color-primary);
                        &.disabled {
                          background-color: var(--ion-color-light) !important;
                        }
                      }
                      .remarks-vertical-line {
                        height: auto;
                        min-height: 65px;
                        width: 1px;
                        background-color: var(--ion-color-light);
                        &.done {
                          background-color: transparent !important;
                        }
                      }
                    }
                  }
                  .additional-details-remarks-status-col {
                    display: flex;
                    flex-direction: column;
                    .status-detail-container {
                      height: 100%;
                      max-height: 76px;
                      display: flex;
                      flex-direction: column;
                      .remarks-status-container {
                        display: flex;
                        flex-direction: row;
                        .status-label {
                          margin-right: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .mb-view-appointment-buttons-container {
      margin: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .view-appointment-button {
        --height: 100%;
        max-width: 120px;
        width: 100%;
        border-radius: 4px;
        margin: 5px;
        &.back {
          border: 1px solid var(--ion-color-primary);
        }
        &.confirm {
          filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
        }
        .cancel-icon {
          margin-right: 5px;
          background: url("../../assets/icons/cancel-button-icon.svg") no-repeat
            center center;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

.mb-view-qr-dialog {
  --height: 500px;
  --width: 100%;
  --max-width: 350px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  .modal-wrapper {
    max-width: 100%;
    width: 350px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      .modal-content {
        overflow: auto;
      }
    }
  }
  .mb-qr-code-header {
    height: 100%;
    padding: 20px;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid var(--ion-color-medium);
  }
  .mb-qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    height: 100%;
    .mq-qr-code-note {
      text-align: center;
      margin-bottom: 20px;
    }
    .mb-qr-code-back-button {
      margin-top: 20px;
      border: 1px solid var(--ion-color-primary);
      border-radius: 4px;
      --width: 100%;
    }
  }
}
