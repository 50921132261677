@import "../../theme/shared.scss";

:root {
  .react-calendar {
    height: 100%;
    width: 256px;
    border: none;
    .react-calendar__navigation {
      button[disabled] {
        // background-color: white;
        color: gray;
      }
    }

    &.mb-mini-custom-calendar {
      .react-calendar__month-view__days__day {
        // border-radius: 50%;
        margin: 2px;
        &.mb-mini-profile-calendar-tile {
          height: 32px !important;
          width: 32px !important;
          flex-basis: 12.6% !important;
          max-width: 12.6% !important;
          color: black;
          font-size: 12px;
          font-weight: 400;
          // background-color: white;

          &.react-calendar__tile--active {
            background: var(--ion-color-primary);
            // border-radius: 50%;
            color: white;
            font-weight: bold;
          }

          &.react-calendar__tile--now {
            background: unset;
            color: white;
          }

          &.react-calendar__tile:disabled {
            color: white;
          }
        }
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      font-size: 11px;
      font-weight: 500;

      abbr {
        text-decoration: none;
      }
    }

    .react-calendar__month-view__days {
      justify-content: space-around;
    }
  }
}
