.mb-card {
  width: 600px;
  &.tech-admin {
    width: 1000px;
  }
  .mb-card-header {
    display: flex;
    flex-direction: column;
    padding: 30px 30px 20px 30px;
    border-bottom: 1px solid var(--ion-color-medium);
    &.transport {
      border-bottom: 1px solid var(--ion-color-medium);
    }
    .mb-card-header-logo {
      position: absolute;
      right: 0;
      top: 0;
      &.magsikilos {
        margin: 20px 30px;
        height: 80px;
        width: 135px;
        background: url("../../assets/transport-magsikilos-logo.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: center;
        &.mobile {
          margin: 30px 15px !important;
          height: 60px !important;
          width: 115px !important;
          background-size: contain !important;
        }
      }
    }
  }
  .mb-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    .mb-content-container {
      padding: 0px 40px 20px;
    }
    .mb-card-footer {
      display: flex;
      padding: 35px 30px 30px 30px;
      justify-content: space-between;
      &.mobile {
        flex-direction: column-reverse !important;
        align-items: center !important;
        justify-content: center !important;
      }
      .mb-card-approval-container {
        &.mobile {
          width: 100%;
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
        }
      }
      .mb-back-button {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--ion-color-primary);
        height: 45px;
        width: 100px;
        &.mobile {
          margin-top: 10px;
          width: 100% !important;
        }
      }
      .mb-submit-button {
        height: 45px;
        width: 100px;
        &.mobile {
          margin-top: 5px;
          width: 100% !important;
        }
      }
    }
  }
}

.contact-us-footer-links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 63px;
  .contact-us-footer-item {
    --inner-padding-end: 0px;
    .footer-fb-icon {
      height: 31px;
      width: 31px;
      background: url("../../assets/icons/fb-circle-icon.svg") no-repeat center
        center;
      margin-right: 14px;
    }
  }
}

@media (max-width: 414px) {
  .mb-card {
    width: 100%;
  }
}
