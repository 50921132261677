.mb-dropdown-select-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  --inner-padding-end: 0;
  width: inherit;
  text-align: left;
  .mb-dropdown-select-label {
    margin-bottom: 10px !important;
    &.has-focus {
      color: var(--ion-color-primary) !important;
    }
    &.has-error {
      color: var(--ion-color-danger) !important;
    }
  }
  .mb-dropdow-select-input-list-container {
    width: inherit;
    display: flex;
    flex-direction: column;
    .mb-dropdown-select-input {
      height: 37px;
      width: inherit;
      border-bottom: 1px solid var(--ion-color-medium);
      margin-bottom: 4px;
      text-overflow: ellipsis;
      --padding-start: 0;
      --padding-end: 0;

      &.has-focus {
        border-bottom: 1px solid var(--ion-color-primary) !important;
      }
      &.has-error {
        border-bottom: 1px solid var(--ion-color-danger) !important;
      }
      .mb-dropdown-spinner {
        right: 0;
        position: absolute;
      }
      .mb-dropdown-select-icon {
        position: absolute;
        right: 0;
        padding-right: 10px;
        color: var(--ion-color-medium);
      }
    }
    .mb-dropdown-select-list {
      border: 1px solid var(--ion-color-medium);
      display: flex;
      flex-direction: column;
      position: absolute;
      margin-top: 37px;
      height: auto;
      z-index: 3;
      width: inherit;
      align-items: flex-start;
      max-height: 150px;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: var(--ion-color-white);
      // width: 95%;

      &::-webkit-scrollbar {
        display: none;
      }
      .mb-dropdown-select-list-container {
        width: inherit;
        height: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 15px;
      }
      .mb-dropdown-select-list-container:hover {
        background: var(--ion-color-medium);
        cursor: pointer;
        .mb-dropdown-select-list-label {
          color: var(--ion-color-dark) !important;
        }
      }
    }
  }
}

.mb-dropdown-select-error-label {
  position: absolute;
  &.has-error {
    color: var(--ion-color-danger) !important;
  }
}
