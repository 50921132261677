.contact-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .contact-us-icon {
    margin: 30px 0px;
    width: 320px;
    height: 339px;
    background: url(../../assets/contact-us-logo.svg) no-repeat center;
  }
  .contact-us-card {
    max-width: 1000px;
    height: 100%;
    max-height: 469px;
    width: 100%;
    border-radius: 4px;
    .contact-us-card-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 120px;
      justify-content: center;
      padding: 30px;
    }
    .contact-us-card-content {
      padding: 30px;
      .contact-us-content-header-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .contact-us-form-container {
        margin-top: 30px;
        .contact-us-row {
          margin-bottom: 20px;
          .contact-us-right-col-input {
            padding-right: 15px;
          }
          .contact-us-left-col-input {
            padding-left: 15px;
          }
        }
        .contact-us-button-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .contact-us-submit-button {
            height: 40px;
            width: 240px;
            --border-radius: 5px;
            filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
          }
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .contact-us-card {
    width: 100%;
  }
}
