@import "../../theme/shared.scss";

.mb-booklist-dialog {
  --height: auto;
  --width: auto;
  --max-width: 1250px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 1250px;
    max-height: 750px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      background: var(--ion-color-tertiary);
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .mb-booklist-dialog-children-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mb-booklist-dialog-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 49px;
      border-bottom: 1px solid var(--ion-color-medium);
      width: 100%;
      .mb-booklist-dialog-title-label {
        text-align: left;
      }
    }

    .mb-booklist-dialog-content {
      padding: 20px 50px;
      width: 100%;
      .mb-booklist-dialog-details {
        border-right: 1px solid var(--ion-color-medium);
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        // overflow-y: auto;
        // max-height: 700px;

        .mb-booklist-dialog-details-header {
          padding: 20px;
          margin: 5px;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);
          background: var(--ion-color-tertiary);
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          .mb-booklist-dialog-details-checkbox-container {
            display: flex;
            align-items: center;
            .mb-booklist-dialog-details-select-all-checkbox {
              margin-right: 20px;
            }
          }

          .mb-booklist-dialog-details-delete {
            height: 30px;
          }
        }

        .mb-booklist-booking-resource-list-container {
          margin-top: 10px;
          max-height: 550px;
          overflow-y: auto;
          padding: 5px;
          padding-bottom: 50px;
          .mb-booklist-booking-card {
            background: var(--ion-color-tertiary);
            padding: 25px;
            margin-top: 20px;
            border-radius: 10px;

            .mb-booklist-booking-card-content {
              display: flex;
              height: 100%;
              .mb-booklist-booking-card-content-checkbox {
                align-self: center;
                width: 27px;
              }

              .mb-booklist-booking-card-doctor-icon {
                height: 101px;
                width: 101px;
                padding: 0 32px 0 20px;
                background: url(../../assets/icons/detail-doctor-resource.svg)
                  no-repeat center center;
              }

              .mb-booklist-booking-card-service-icon {
                height: 101px;
                width: 101px;
                padding: 0 32px 0 20px;
                background: url(../../assets/icons/detail-service-resource.svg)
                  no-repeat center center;
              }

              .mb-booklist-booking-card-resource-details {
                display: flex;
                flex-direction: column;
                width: 100%;
                .mb-booklist-booking-card-hospital {
                  margin-top: 5px;

                  .mb-booklist-booking-card-resource-type {
                    opacity: 0.5;
                  }
                }

                .mb-booklist-booking-card-time-container {
                  display: flex;
                  margin-top: 15px;
                }

                .mb-booklist-booking-card-fee {
                  margin-top: 20px;
                }
              }

              .mb-booklist-edit-button-container {
                display: flex;
                align-items: flex-end;
              }
            }
          }
        }
      }

      .mb-booklist-dialog-summary {
        padding-left: 15px;
        display: flex;
        flex-direction: column;

        .mb-booklist-dialog-summary-label {
          margin-bottom: 25px;
        }

        .mb-booklist-dialog-summary-booking-fees-container {
          background: var(--ion-background-color);
          padding: 30px 25px;
          margin-top: 25px;
          .mb-booklist-dialog-summary-booking-fees-item {
            display: flex;
            justify-content: space-between;
            padding: 12.5px 0;
          }
        }

        .mb-booklist-dialog-summary-booking-fees-total-container {
          background: var(--ion-background-color);
          padding: 13px 25px;
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
        }

        .mb-booklist-dialog-submit-container {
          margin-top: 10px;
          display: flex;

          .mb-booklist-dialog-submit-button {
            --background: var(--ion-color-success);
            width: 100%;
            height: 53px;
          }
        }
      }
    }
  }
}
