.mb-dropdown-multiple-select-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  --inner-padding-end: 0;
  width: inherit;
  text-align: left;
  .mb-dropdown-multiple-select-label {
    margin-bottom: 10px !important;
    &.has-focus {
      color: var(--ion-color-primary) !important;
    }
    &.has-error {
      color: var(--ion-color-danger) !important;
    }
  }
  .mb-dropdow-select-input-list-container {
    width: inherit;
    display: flex;
    flex-direction: column;
    .mb-dropdown-multiple-select-chip-input-container {
      display: flex;
      flex-direction: row;
      height: auto;
      width: inherit;
      border-bottom: 1px solid var(--ion-color-secondary);
      margin-bottom: 4px;
      --padding-start: 15px !important;
      --padding-end: 25px;
      text-overflow: ellipsis;
      overflow-x: scroll;
      &.has-focus {
        border-bottom: 2px solid var(--ion-color-secondary) !important;
      }
      &.has-error {
        border-bottom: 1px solid var(--ion-color-danger) !important;
      }
      .mb-dropdown-multiple-select-chip {
        // border: 1px solid var(--ion-color-primary);
        border-radius: 19px;
        background: var(--ion-color-primary-light);
        padding: 10px;
        height: 37px;
        margin-top: 1px;
        margin-bottom: 8px;
        min-width: max-content;
        .mb-dropdown-multiple-select-chip-icon {
          height: 21px;
          width: 21px;
          color: var(--ion-color-primary);
        }
      }
      .mb-dropdown-multiple-select-input {
        padding-left: 20px;
        .mb-dropdown-multiple-select-icon {
          position: absolute;
          right: 0;
          padding-right: 10px;
          color: var(--ion-color-dark);
        }
      }
    }
    .mb-dropdown-multiple-select-list {
      border: 1px solid var(--ion-color-medium);
      display: flex;
      flex-direction: column;
      position: absolute;
      margin-top: 37px;
      height: auto;
      z-index: 3;
      width: inherit;
      align-items: flex-start;
      max-height: 100px;
      overflow-y: auto;
      overflow-x: hidden;
      &.mobile {
        width: 82% !important;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      .mb-dropdown-multiple-select-list-container {
        width: inherit;
        height: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 15px;
      }
      .mb-dropdown-multiple-select-list-container:hover {
        background: var(--ion-color-tertiary);
        cursor: pointer;
        .mb-dropdown-multiple-select-list-label {
          color: var(--ion-color-secondary) !important;
        }
      }
    }
  }
}

.mb-dropdown-multiple-select-error-label {
  position: absolute;
  &.has-error {
    color: var(--ion-color-danger) !important;
  }
}
