.checkout-container {
  .checkout-card {
    background: var(--ion-color-tertiary);
    width: 705px;
    margin: auto;
    .checkout-card-header {
      border-bottom: 1px solid var(--ion-color-medium);
      padding: 22px 45px;
    }
    .checkout-card-content {
      padding: 20px 45px;

      .checkout-card-payment-methods-radio-group {
        .checkout-card-payment-methods {
          padding-bottom: 40px;
          margin-bottom: 32px;
          border-bottom: 1px solid var(--ion-color-medium);
          .checkout-card-payment-method-col {
            padding-bottom: 15px;
            .checkout-card-payment-method-image {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 20px 0;
              border: 1px solid var(--ion-color-medium);
              border-radius: 10px;
              margin: 0 35px;
              .checkout-card-payment-method-image-booking-credit {
                height: 52px;
                width: 82px;
              }
              .checkout-card-payment-method-image-gcash {
                height: 52px;
                width: 82px;
                background: url(../../assets/icons/payment-method-gcash.svg)
                  no-repeat center center;
              }
              .checkout-card-payment-method-image-credit-debit-card {
                height: 52px;
                width: 82px;
                background: url(../../assets/icons/payment-method-credit-debit.svg)
                  no-repeat center center;
              }
            }
          }
        }
      }

      .checkout-card-payment-method-credit-debit-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .checkout-card-payment-method-cards-collection {
          width: 261px;
          height: 61px;
          background: url(../../assets/icons/card-visa.svg) no-repeat center
            center;
        }
      }

      .checkout-card-payment-method-credit-debit-grid {
        margin-bottom: 50px;
        .checkout-card-payment-method-credit-debit-col {
          .checkout-card-payment-method-credit-debit-detail-date-input {
            border-bottom: 1px solid var(--ion-color-medium);
            // --padding-bottom: 2px;
          }
        }
      }

      .checkout-card-payment-method-gcash-grid {
        margin-bottom: 50px;

        .checkout-card-payment-method-gcash-col {
          padding-bottom: 15px;
        }
      }

      .checkout-card-payment-summary-label {
        margin-bottom: 25px;
      }

      .checkout-card-payment-summary-booking-fees-container {
        background: var(--ion-background-color);
        padding: 30px 25px;
        margin-top: 25px;
        .checkout-card-payment-summary-booking-fees-item {
          display: flex;
          justify-content: space-between;
          padding: 12.5px 0;
        }
      }

      .checkout-card-payment-summary-booking-fees-total-container {
        background: var(--ion-background-color);
        padding: 13px 25px;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
      }

      .checkout-card-payment-tnc-item {
        margin-top: 73px;
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        justify-content: center;
        .checkout-card-payment-tnc-checkbox {
          margin: 0 17px;
          --border-color: var(--ion-color-primary);
          --size: 23px;
        }
        .checkout-card-payment-tnc-label {
          text-align: start;
          white-space: unset;
        }
      }

      .checkout-card-payment-submit-button {
        --background: var(--ion-color-success);
        margin-top: 20px;
        width: 100%;
        height: 53px;
      }
    }
  }
}
