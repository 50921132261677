.hospital-home-dashboard-card-container {
  display: flex;
  flex-direction: row;
}
.hospital-home-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  .hospital-home-icon {
    background: url("../../assets/hospital-home-logo.svg") no-repeat center
      center;
    width: 366px;
    height: 261px;
  }
  .hospital-home-title {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.hospital-home-button-container {
  margin-top: 25px;
  .hospital-home-goto-button {
    width: 200px;
    height: 42px;
    border: 1px solid var(--ion-color-primary);
    border-radius: 5px;
  }
  .hospital-home-add-doctor-services {
    width: 375px;
    height: 42px;
  }
}
.hospital-home-add-dialog {
  --max-width: 791px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  --max-height: 450px;
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 791px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      height: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .hospital-add-dialog-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .hospital-add-dialog-header {
      text-align: center;
      align-self: center;
    }
    .hospital-add-dialog-description {
      text-align: center !important;
      align-self: center;
      margin: 25px 0px;
      width: 70%;
    }
    .hospital-add-dialog-options-card-types-container {
      display: flex;
      margin-bottom: 23px;
      justify-content: space-evenly;
      &.mobile {
        flex-direction: column;
        width: 100%;
      }
      .hospital-add-dialog-button-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: space-around;
        .hospital-add-dialog-options-card-types-button {
          width: 171px;
          height: 199px;
          cursor: pointer;
          border-radius: 10px !important;
          background: #fafbfd;
          border: none;
          &.isSelected {
            background: var(--ion-color-white) !important;
            border: 1px solid var(--ion-color-primary) !important;
          }
          .hospital-add-dialog-user-button-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            height: 100%;
            .hospital-add-dialog-icon {
              height: 80px;
              width: 80px;
              &.patient {
                background: url("../../assets/icons/user-doctor.svg") no-repeat
                  center center;
              }
              &.ambulatory {
                background: url("../../assets/icons/ambulatory-option-icon.svg")
                  no-repeat center center;
              }
              &.timeKeeping {
                background: url("../../assets/icons/time-keeping-add-icon.svg")
                  no-repeat center center;
              }
            }
            .hospital-add-dialog-user-label {
              color: var(--ion-color-medium);
              &.isSelected {
                color: var(--ion-color-dark) !important;
              }
            }
          }
        }
      }
    }
    .hospital-add-dialog-next-button {
      filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
      width: 180px;
      height: 42px;
      align-self: center;
    }
  }
}
