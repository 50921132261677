.time-keeping-patient-list-container {
  width: 100%;

  .time-keeping-patient-list-search-card-container {
    display: flex;
    align-items: center;
    background: var(--ion-color-tertiary);
    width: 100%;
    padding: 31px 25px 35px;
    margin-bottom: 25px;
    overflow-y: hidden;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    .time-keeping-patient-list-search-input {
      margin: 0 3px;
      --background: var(--ion-color-tertiary);
      border-radius: 4px;
      border: 1px solid var(--ion-color-medium);
      height: 41px;
    }

    .time-keeping-patient-list-search-submit {
      margin: 0 3px;
      width: 100%;
      max-width: 104px;
      filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
    }
  }
}
