.family-member-form-modal {
  --height: auto;
  --width: auto;
  --max-width: 817px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  --background: var(--ion-color-tertiary);
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 613px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 100%;
      .modal-content {
        overflow: auto;
      }
    }
  }
  .family-member-form-header {
    padding: 25px;
  }
  .family-member-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 600px;
    padding: 0px 35px 20px 35px;
    overflow-y: auto;
    &.hidden {
      display: none;
    }
    .family-member-form-description-container {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      &.mobile {
        padding: 0px !important;
      }
    }
    .family-member-form-container-grid {
      width: 100%;
      margin-top: 30px;
      &.footer {
        margin-bottom: 0;
      }
      .family-member-form-container-col {
        padding-bottom: 30px;
        &.mobile {
          padding: 0px 0px 30px 0px !important;
        }
        .family-member-form-birthday-input {
          margin-top: 7px;
          margin-left: 15px;
        }
        .family-member-form-date-container {
          padding-left: 15px;
          .family-member-form-birthday-input {
            border-bottom: 1px solid var(--ion-color-medium);
          }
        }
        .family-member-form-select-container {
          width: 100%;
        }
      }
    }
  }
  .family-member-form-setup-button-container {
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .family-member-form-setup-back-button {
      border: 1px solid var(--ion-color-primary);
      border-radius: 5px;
      width: 100px;
    }
    .family-member-form-setup-save-button {
      border-radius: 5px;
      width: 100px;
    }
  }
}
