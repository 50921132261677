@import "../../theme/shared.scss";

.mb-dialog {
  --height: auto;
  --width: auto;
  --max-width: 500px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 500px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .mb-dialog-children-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    .mb-dialog-icon {
      height: 70px;
      width: 70px;
      &.success {
        background: url("../../assets/icons/success.svg") no-repeat center
          center;
      }
      &.warning {
        background: url("../../assets/icons/warning.svg") no-repeat center
          center;
      }
      &.time-keeping {
        height: 96px;
        width: 96px;
        background: url("../../assets/icons/time-keeping-dialog.svg") no-repeat
          center center;
      }
      &.services {
        background: url("../../assets/icons/ambulatory-service-added.svg")
          no-repeat center center;
        height: 97px;
        width: 97px;
      }
      &.doctor {
        background: url("../../assets/icons/new-doctor.svg") no-repeat center
          center;
        height: 96px;
        width: 96px;
      }
      &.assistant {
        background: url("../../assets/icons/new-assistant.svg") no-repeat center
          center;
        height: 98px;
        width: 116px;
      }
      &.multi-booking {
        background: url("../../assets/icons/multi-booking.svg") no-repeat center
          center;
        height: 270px;
        width: 250px;
      }
      &.family-member {
        background: url("../../assets/icons/family-member.svg") no-repeat center
          center;
        height: 108px;
        width: 122px;
      }
    }
    .mb-dialog-title-label {
      margin-top: 15px;
      margin-bottom: 15px;
      text-align: center;
    }
    .mb-dialog-message-label {
      text-align: center;
    }
    .mb-dialog-options-container {
      display: flex;
      padding-top: 30px;
      justify-content: space-between;
      width: 100%;
      .mb-dialog-no-button {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--ion-color-medium);
        height: 30px;
        width: 100px;
      }
      .mb-dialog-submit-button {
        height: 30px;
        width: 100px;
      }
    }
    .mb-dialog-action-button {
      height: 46px !important;
      width: 176px;
      margin-top: 55px;
      filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
    }
  }
}
