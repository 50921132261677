.filter-appointments-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .filter-appointments-container {
    width: 100%;

    ion-checkbox {
      --size: 23px;
    }

    .filter-appointments-select-container {
      width: 100%;
    }
  }
}
