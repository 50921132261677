.ongoing-verify-card {
  height: 437px;
  width: 548px;
  .ongoing-verify-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    .verify-icon {
      height: 182px;
      width: 187px;
      margin-bottom: 10px;
      &.success {
        background: url("../../assets/icons/success.svg") no-repeat center
          center;
      }
      &.warning {
        background: url("../../assets/icons/ongoing-verification-logo.svg")
          no-repeat center center;
      }
    }
    .resend-email-description {
      margin-top: 20px;
      text-align: center;
      width: 360px;
    }
    .verify-ok-button {
      height: 42px;
      width: 420px;
      margin-top: 30px;
      filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
    }
  }
}
