@import "../../theme/shared.scss";

.login-main-container {
  height: 100%;
  width: 100%;
  .login-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}
