.time-keeping-scan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
}

@media (max-width: 414px) {
  .time-keeping-scan-container {
    margin-top: 45px;
  }
}
