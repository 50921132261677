.terms-and-condition-card {
  width: 850px;
  padding: 25px;
  height: 1000px;
  overflow-y: scroll;
  text-align: justify;
  &.mobile {
    width: 100%;
  }
  .c1 {
    &.lst-kix_list_20-0 {
      padding-left: 15px;
      &.content-4 {
        margin-top: 40px;
      }
    }
    &.lst-kix_list_29-0 {
      padding-left: 15px;
    }
    &.lst-kix_list_31-0 {
      margin-top: 40px;
      padding-left: 15px;
      &.ten {
        margin-left: 8px;
      }
    }
    &.lst-kix_list_32-0 {
      padding-left: 21px;
    }
  }

  .user-responsibility {
    margin-left: 30px;
  }
  .content-provided {
    margin-left: 30px;
  }
}

p.c0 {
  margin-left: 30px !important;
}
