.payment-processing-landing-card {
  width: 510px;
  background: var(--ion-color-tertiary);
  margin: auto;
  .payment-processing-landing-card-content {
    padding: 45px 75px;
    padding: 45px 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .payment-processing-landing-card-icon {
      width: 186px;
      height: 164px;
      margin: 50px 0 45px 30px;
      background: url(../../assets/icons/payment-processing.svg) no-repeat
        center center;

      &.failed {
        width: 190px;
        height: 164px;
        background: url(../../assets/icons/payment-failed-gcash.svg) no-repeat
          center center;
      }
    }

    .payment-processing-landing-card-paymongo-icon {
      width: 140px;
      height: 24px;
      background: url(../../assets/icons/paymongo-icon.svg) no-repeat center
        center;
    }
  }
}
