.hospital-account-registration-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.hidden {
    display: none;
  }
  .hospital-account-registration-form-container-grid {
    width: 100%;
    margin-top: 30px;
    &.footer {
      margin-bottom: 0;
    }

    .hospital-account-registration-form-container-col {
      padding-bottom: 30px;
      &.has-dropdown-input {
        padding-bottom: 30px !important;
      }
      &.mobile {
        padding-bottom: 30px !important;
      }

      .hospital-account-setup-dropdown-container {
        width: 100%;
      }
      .hospital-account-registration-form-container-col-select-container {
        width: 255px;
      }
      .hospital-account-registration-form-department-dropdown-container {
        width: 255px;
        &.mobile {
          width: 100%;
        }
      }
      .hospital-account-registration-form-container-col-select-hospital {
        width: 540px;
      }
    }
  }
  .hospital-account-registration-form-data-privacy-consent-item {
    display: flex;
    height: 23px;
    margin-bottom: 5px;
    align-items: center;
    justify-content: flex-start;
    .hospital-account-registration-form-data-privacy-consent-checkbox {
      margin-right: 17px;
      --border-color: var(--ion-color-light);
      --size: 23px;
    }
    .hospital-account-registration-form-data-privacy-consent-label {
      text-align: start;
    }
  }
  .hospital-account-registration-form-footer-description-container {
    display: flex;
    flex-direction: column;
  }

  .hospital-account-registration-can-provide-transpo-container {
    padding: 0 15px;
  }
}

.hospital-account-registration-covid-safe-list {
  margin-top: 10px;
}

a {
  cursor: pointer;
}
