.mb-appointments-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .mb-appointments-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .mb-appointments-header-label {
    }
    .mb-appointments-action-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      .mb-appointments-list-button {
        min-width: 50px;
        width: 100%;
        height: 48px;
        border: 1px solid var(--ion-color-light);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        &.active {
          border: 1px solid var(--ion-color-primary);
        }
      }
      .mb-appointments-grid-button {
        min-width: 50px;
        width: 100%;
        height: 48px;
        border: 1px solid var(--ion-color-light);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        &.active {
          border: 1px solid var(--ion-color-primary);
        }
      }
      .mb-appointments-status-other-button {
        min-width: 40px;
        width: 100%;
        height: 48px;
      }
    }
  }

  .mb-appointments-status-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid var(--ion-color-medium-light);
    .mb-appointments-status-button {
      min-width: 100px;
      border-bottom: none;
      &.active {
        border-bottom: 4px solid var(--ion-color-primary);
      }
    }
  }
}

.mb-filter-popover {
  --box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.1);
  margin-top: 7px;
  --max-width: 200px;
  --width: 100%;
  .popover-content {
    border-radius: 0px;
    background-color: var(--ion-color-tertiary);
    left: 83% !important;
    .mb-filter-list-container {
      display: flex;
      flex-direction: column;
      .mb-filter-list {
        background-color: var(--ion-color-tertiary);
        .mb-filter-item-container {
          width: 100%;
          background-color: var(--ion-color-tertiary) !important;
          .mb-filter-dropdown-modal-item {
            --inner-padding-end: 0;
            --padding-start: 0;
            --background: var(--ion-color-tertiary);
            .mb-filter-dropdown-label {
              display: flex;
              align-items: center;
              .mb-filter-dropdown-modal-item-icon {
                margin-left: 10px;
                margin-right: 10px;
                height: 21px;
                width: 21px;
                opacity: 0.2;
                &.download {
                  background: url("../../assets/icons/download-icon.svg")
                    no-repeat center center;
                }
                &.filter {
                  background: url("../../assets/icons/filter-icon.svg")
                    no-repeat center center;
                }
                &.clear {
                  background: url("../../assets/icons/clear-schedule-icon.svg")
                    no-repeat center center;
                }
              }
            }
          }
        }
      }
    }
  }
}
