.transport-account-setup-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .transport-account-setup-form-description-container {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    &.mobile {
      padding: 0px !important;
    }
  }
  .transport-account-setup-form-container-grid {
    width: 100%;
    margin-top: 30px;
    .transport-account-setup-form-container-col {
      padding: 0 15px 30px 15px;
      &.last {
        padding-bottom: 25px;
      }
      &.mobile {
        padding: 0px 0px 30px 0px !important;
      }
    }
  }
  .transport-account-setup-form-footer-description-container {
    display: flex;
    flex-direction: column;
    padding: 0 15px 20px 15px;

    .transport-account-setup-footer-checklist-details {
      margin-top: 20px;
      .transport-account-setup-footer-checklist-item {
        display: flex;
        height: 23px;
        margin-bottom: 7px;
        .transport-account-setup-footer-checklist-checkbox {
          margin-right: 17px;
          --border-color: var(--ion-color-tertiary);
          --size: 23px;
        }
        .transport-account-setup-footer-checklist-label {
          text-align: start;
        }
      }
    }
  }
}

a {
  cursor: pointer;
  color: #1b91ff !important;
}
