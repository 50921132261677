.mb-yesno-radio-group-container {
  width: inherit;
  .mb-yesno-radio-title-col {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    .mb-yesno-title-label {
      align-self: flex-start;
      text-align: start;
    }
  }
  .mb-yesno-radio {
    margin-right: 15px !important;
  }
}
