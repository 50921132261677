.hospital-account-setup-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.hidden {
    display: none;
  }
  .hospital-account-setup-form-description-container {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
  }
  .hospital-account-setup-form-container-grid {
    width: 100%;
    margin-top: 30px;
    &.footer {
      margin-bottom: 0;
    }
    .hospital-account-setup-form-container-col {
      padding: 0 15px 30px 15px;
      text-align: justify;
      .hospital-account-setup-form-container-col-select-hospital {
        width: 540px;
        &.mobile {
          width: 100% !important;
        }
      }

      .hospital-account-setup-form-id {
        height: 200px;
        cursor: pointer;
      }
    }
  }
  .hospital-account-setup-form-footer-description-container {
    display: flex;
    flex-direction: column;
    padding: 0px 15px 0 15px;
  }

  .hospital-account-setup-can-provide-transpo-container {
    padding: 0 15px;
  }
}

.hospital-account-setup-covid-safe-list {
  margin-top: 10px;
}

a {
  cursor: pointer;
}
