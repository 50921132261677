@import "../../theme/shared.scss";

.mb-calendar-card {
  margin: 20px 0px;
  // height: 580px;
  .react-calendar {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    height: 100%;
    width: 100%;
    border: none;
    .react-calendar__navigation {
      margin-bottom: 21px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 10px;
      background-color: rgba(#c4c4c4, 0.3) !important;
      button[disabled] {
        color: var(--ion-color-light-10);
      }
      button {
        background-color: white;
      }

      .react-calendar__navigation__label {
        background-color: transparent !important;
        flex-grow: 0 !important;
        font-size: $wcH2FontSize;
        font-weight: $wcH2FontWeight;
        line-height: $wcH2LineHeight;
      }

      .react-calendar__navigation__arrow {
        background-color: transparent !important;
        ion-icon {
          background-color: transparent !important;
          height: 25px;
          width: 25px;
          color: var(--ion-color-dark);
        }
      }
    }

    &.mb-custom-calendar {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      .react-calendar__month-view__weekdays {
        margin-bottom: 15px;
        border-bottom: 1px solid rgba(#c4c4c4, 0.4);
        padding-bottom: 15px;
        justify-content: space-between;
        text-align: center;
      }
      .react-calendar__month-view__days__day {
        white-space: nowrap;
        &.mb-profile-calendar-tile {
          font-size: $wcCalendarDayFontSize;
          font-weight: $wcCalendarDayFontWeight;
          line-height: $wcCalendarDayLineHeight;
          margin-bottom: 20px;
          background-color: white;

          &.react-calendar__month-view__days__day--neighboringMonth {
            color: #c4c4c4;
          }

          &.react-calendar__tile--active {
            -webkit-touch-callout: none !important;
            -webkit-user-select: none !important;
            -khtml-user-select: none !important;
            -moz-user-select: none !important;
            -ms-user-select: none !important;
            user-select: none !important;

            &:focus {
              outline: none !important;
              color: var(--ion-color-secondary);
            }

            font-weight: bold;
            color: var(--ion-color-secondary);
            text-decoration: underline;
            // background: var(--ion-color-primary);
          }

          &.react-calendar__tile--now {
            font-weight: bold;
            color: var(--ion-color-dark);
            border: 1px solid var(--ion-color-light);
            // border-radius: 10px;
          }
          &.has-appointment {
            font-weight: bold;
            color: var(--ion-color-primary);
            // border: 1px solid var(--ion-color-primary);
            // background: rgba(#57b9c6, 0.1);
            text-decoration: underline;
            // border-radius: 10px;
          }

          &.react-calendar__tile:disabled {
            background-color: white;
            color: var(--ion-color-medium-light);
          }
        }
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      font-size: $wcCalendarWeekDayFontSize;
      font-weight: $wcCalendarWeekDayFontWeight;
      line-height: $wcCalendarWeekDayLineHeight;

      abbr {
        text-decoration: none;
      }
    }

    .react-calendar__month-view__days {
      justify-content: space-around;
    }
  }
}

// .react-calendar--doubleView {
//   width: 700px;
// }
// .react-calendar--doubleView .react-calendar__viewContainer {
//   display: flex;
//   margin: -0.5em;
// }
// .react-calendar--doubleView .react-calendar__viewContainer > * {
//   width: 50%;
//   margin: 0.5em;
// }
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.5em 0.75em;
  background: none;

  // margin: 20px;
}
.react-calendar__tile--now {
  background: var(--ion-color-primary);
  color: white;
  font-weight: bold;
}
