@import "./shared";
@import "./custom.scss";

:root {
  --ion-color-primary: #57b9c6;
  --ion-color-primary-rgb: 87, 185, 198;
  --ion-color-primary-light: #cdf1f4;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #4da3ae;
  --ion-color-primary-tint: #68c0cc;

  --ion-color-secondary: #104c82;
  --ion-color-secondary-rgb: 16, 76, 130;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0e4372;
  --ion-color-secondary-tint: #285e8f;

  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 255, 255, 255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e0e0e0;
  --ion-color-tertiary-tint: #ffffff;

  --ion-color-success: #57c6aa;
  --ion-color-success-rgb: 87, 198, 170;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #4dae96;
  --ion-color-success-tint: #68ccb3;

  --ion-color-warning: #ffa403;
  --ion-color-warning-rgb: 255, 164, 3;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e09003;
  --ion-color-warning-tint: #ffad1c;

  --ion-color-danger: #cc212a;
  --ion-color-danger-rgb: 204, 33, 42;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b41d25;
  --ion-color-danger-tint: #d1373f;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium-dark: #585858;
  --ion-color-medium-dark-rgb: 88, 88, 88;
  --ion-color-medium-dark-contrast: #ffffff;
  --ion-color-medium-dark-contrast-rgb: 255, 255, 255;
  --ion-color-medium-dark-shade: #4d4d4d;
  --ion-color-medium-dark-tint: #696969;

  --ion-color-medium: #dbdbdb;
  --ion-color-medium-rgb: 219, 219, 219;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #c1c1c1;
  --ion-color-medium-tint: #dfdfdf;

  --ion-color-light: #a6a6a6;
  --ion-color-medium-light: #eeeeee;
  --ion-color-light-rgb: 166, 166, 166;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #929292;
  --ion-color-light-tint: #afafaf;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: var(--ion-color-medium);
  --ion-color-white-contrast-rgb: var(--ion-color-medium-rgb);
  --ion-color-white-shade: #686671;
  --ion-color-white-border: #373940;
  --ion-color-white-tint: #84828d;

  //OTHERS
  //CARD COLORS
  --ion-color-consultation: #53caec;
  --ion-color-treatment: #ee8434;
  --ion-color-cancelled: #9d9d9d;
  --ion-background-color: #fafafa;
  --ion-background-color-rgb: 229, 229, 229;
}

ion-card {
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

ion-button {
  --border-radius: 5px;
  --box-shadow: none;
}

ion-slide {
  // pointer-events: none;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;

  &:focus {
    outline: none !important;
  }
}

ion-toast {
  color: var(--ion-color-white) !important;
}

ion-slides {
  overflow: visible !important;
}

@media (max-width: 414px) {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  ion-modal {
    .modal-wrapper {
      width: 90% !important;
    }
  }
}
