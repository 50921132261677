.mb-footer-container {
  background: var(--ion-color-secondary);
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .mb-footer-copy-right-label {
    margin-left: 30px;
    text-decoration: none;
  }
  .mb-footer-training-notice-label {
    margin: auto;
    &.mobile {
      margin: 0px !important;
    }
  }

  .mb-footer-links-container {
    .mb-footer-privacy-label {
      text-decoration: none;
      margin-right: 23px;
    }
    .mb-footer-terms-condition-label {
      text-decoration: none;
      margin-right: 23px;
    }
  }
}

@media (max-width: 600px) {
  .mb-footer-container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: 135px !important;
    text-align: center !important;
  }
  .mb-footer-copy-right-label {
    margin-top: 15px !important;
    margin-left: 0px !important;
    margin-bottom: 7px;
    width: 80% !important;
  }
  .mb-footer-training-notice-label {
    margin-left: 15px !important;
    width: 100%;
    font-size: 11px !important;
  }
  .mb-footer-links-container {
    position: relative !important;
    margin-top: 2px;
    width: 100%;
    margin-bottom: 15px;
    .mb-footer-terms-condition-label {
      text-decoration: none;
      margin-right: 0px !important;
    }
  }
}
