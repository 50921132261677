@import "../../theme/shared.scss";

.mb-checkout-success {
  --height: auto;
  --width: auto;
  --max-width: 500px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 500px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .mb-checkout-success-children-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    .mb-checkout-success-title-label {
      text-align: center;
    }

    .mb-checkout-success-message-label {
      text-align: center;
      margin-top: 30px;
    }

    .mb-checkout-success-image-container {
      margin-top: 20px;
      .mb-checkout-success-icon {
        margin-bottom: 15px;
        height: 70px;
        width: 70px;
        background: url("../../assets/icons/multi-booking.svg") no-repeat center
          center;
        height: 270px;
        width: 250px;
        margin-left: 50px;
      }
    }

    .mb-checkout-success-options-container {
      display: flex;
      justify-content: center;
      padding-top: 30px;
      width: 100%;
      .mb-checkout-success-view-booking-button {
        width: 164px;
      }
      .mb-checkout-success-go-to-home-button {
        width: 164px;
      }
    }
    .mb-checkout-success-action-button {
      width: 80%;
      --border-radius: 5px !important;
      margin-top: 55px;
      filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
    }
  }
}
