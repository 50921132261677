@import "../../theme/shared.scss";

.mb-qr-result-dialog {
  --height: auto;
  --width: auto;
  --max-width: 521px;
  --max-height: 700px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 500px;
    .ion-page {
      position: relative;
      contain: content;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .mb-qr-result-header-container {
    border-bottom: 1px solid var(--ion-color-medium);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .mb-qr-result-header-label {
      align-self: center;
      text-align: center;
      margin: 30px 0px;
    }
  }
  .mb-qr-result-dialog-children-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 0px 60px 0px;
    .mb-qr-result-with-result-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .mb-result-item-components-container {
        padding: 0px 55px 30px 55px;
        &.mobile {
          padding: 0px 40px 15px 40px !important;
        }
      }
      .qr-result-icon-status-container {
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .patient-icon {
          background: url(../../assets/icons/patient-icon.svg) no-repeat center
            center;
          height: 71px;
          width: 71px;
          margin-right: 15px;
        }
        .result-status {
          min-height: 25px;
          min-width: 85px;
          border-radius: 4px;
          font-size: $wcH4FontSize;
          font-weight: 700;
          text-align: center;
          padding-top: 2px;
          &.today {
            color: var(--ion-color-primary);
            background-color: rgba(#57b9c6, 0.25);
          }
        }
      }
      .with-result-patient-name {
        margin-bottom: 15px;
        align-self: flex-start;
      }
      .with-result-hospital-container {
        margin: 15px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .with-result-hospital-icon {
          margin-right: 10px;
          background: url(../../assets/icons/hospital-label-icon.svg) no-repeat
            center center;
          height: 18px;
          width: 15px;
        }
        .with-result-hospital {
          margin-right: 3px;
        }
      }
      .with-result-doctor-container {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .with-result-doctor-icon {
          margin-right: 10px;
          background: url(../../assets/icons/doctor-label-icon.svg) no-repeat
            center center;
          height: 19px;
          width: 18px;
        }
        .with-result-doctor {
          margin-right: 8px;
        }
        .with-result-hospital-icon {
          margin-right: 10px;
          background: url(../../assets/icons/hospital-label-icon.svg) no-repeat
            center center;
          height: 18px;
          width: 15px;
        }
      }
      .with-result-schedule-container {
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        &.mobile {
          flex-direction: column !important;
          align-items: flex-start !important;
        }
        .schedule-icon {
          color: #cfdbe6;
        }
        .with-result-date-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          &.mobile {
            margin-bottom: 10px;
          }
          .with-result-date-label {
            padding-top: 2px;
            margin: 0px 10px;
          }
        }
        .with-result-time-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          .with-result-time-label {
            padding-top: 2px;
            margin: 0px 10px;
          }
        }
      }
      .with-result-covid-container {
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .with-result-covid-icon {
          margin-right: 10px;
          background: url(../../assets/icons/covid-label-icon.svg) no-repeat
            center center;
          height: 21px;
          width: 20px;
        }
      }
      .with-result-booked-days-ago {
        margin-bottom: 15px;
        align-self: flex-start;
      }
      .with-result-qr-buttons-container {
        border-top: 1px solid var(--ion-color-medium);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 20px;
        .with-result-qr-code-container {
          margin-top: 10px;
        }
        .with-result-action-button-container {
          margin: 35px;
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;
          .with-result-button {
            max-width: 155px;
            height: 42px;
            border-radius: 4px;
            width: 100%;
            &.go-back {
              border: 1px solid var(--ion-color-primary);
              margin-right: 5px;
            }
            &.confirm {
              margin-left: 5px;
              filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
            }
          }
        }
      }
    }

    .mb-qr-result-no-result-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .qr-no-result {
        background: url(../../assets/no-qr-result.svg) no-repeat center center;
        height: 182px;
        width: 208px;
      }
      .mb-qr-result-no-result-label {
        margin: 20px 0px 25px 0px;
      }
      .mb-qr-result-no-result-sub-label {
        text-align: center;
        max-width: 313px;
        width: 100%;
      }
      .mb-qr-result-no-result-action-buttons-container {
        padding: 30px 30px 0px 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .try-search-buttons-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 20px;
          width: 100%;
          justify-content: center;
          .try-again-button {
            width: 165px;
            height: 42px;
            border-radius: 4px;
            filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
          }
          .search-button {
            margin-left: 10px;
            width: 165px;
            height: 42px;
            border-radius: 4px;
            filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
          }
        }
        .go-back-button {
          border: 1px solid var(--ion-color-primary);
          border-radius: 4px;
          width: 100%;
          height: 42px;
          max-width: 343px;
        }
      }
    }

    // .mb-qr-result-dialog-action-button {
    //   height: 30px;
    //   margin-top: 55px;
    //   filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
    // }
  }
}
