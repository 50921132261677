.doctor-create-password-form-card {
  max-width: 529px;
  width: 100%;
  height: fit-content;
  .doctor-create-password-form-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 35px 15px;
    .doctor-mb-logo-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .doctor-create-password-form-card-logo {
        background: url(../../assets/icons/medbook-logo.svg) no-repeat center;
        width: 396px;
        height: 93px;
        margin-bottom: 12px;
        &.mobile {
          width: 240px;
          height: 90px;
          margin: 0 auto 12px;
        }
      }
    }
    .doctor-create-password-form-details {
      display: flex;
      flex-direction: column;
      text-align: center;
      max-width: 423px;
      margin: 30px auto 0;
      .doctor-create-password-form-input-container {
        display: flex;
        flex-direction: column;
        .doctor-create-password-form-password-input {
          margin-top: 30px;
        }
        .doctor-create-password-form-confirm-password-input {
          margin-top: 20px;
        }
      }
      .doctor-create-password-privacy-container {
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .doctor-create-password-data-privacy-consent-item {
        margin-top: 73px;
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        justify-content: center;
        .doctor-create-password-data-privacy-consent-checkbox {
          margin: 0 17px;
          --border-color: var(--ion-color-primary);
          --size: 23px;
        }
        .doctor-create-password-data-privacy-consent-label {
          text-align: start;
          white-space: unset;
        }
      }
      .doctor-create-password-form-login-button {
        width: 100%;
        height: 43px;
        margin-top: 30px;
        filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
      }
    }
    .doctor-create-password-terms {
      margin-top: 23px;
      a {
        color: inherit !important;
      }
    }
  }
}
