.account-type-options-card {
  max-width: 426px;
  max-height: 486px;
  height: 100%;
  width: 100%;
  &.mobile {
    width: 100%;
  }
  .account-type-options-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 35px 35px 38px 35px;
    .account-type-header {
      text-align: center;
      align-self: center;
    }
    .account-type-description {
      text-align: center !important;
      align-self: center;
      margin: 25px 0px;
      width: 70%;
    }
    .account-type-options-card-types-container {
      display: flex;
      margin-bottom: 23px;
      justify-content: space-evenly;
      &.mobile {
        flex-direction: column;
        width: 100%;
      }
      .account-type-button-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: space-around;
        .account-type-options-card-types-button {
          width: 171px;
          height: 199px;
          cursor: pointer;
          border-radius: 10px !important;
          background: #fafbfd;
          border: none;
          &.isSelected {
            background: var(--ion-color-white) !important;
            border: 1px solid var(--ion-color-primary) !important;
          }
          .account-type-user-button-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            height: 100%;
            .account-type-icon {
              height: 80px;
              width: 80px;
              &.patient {
                background: url("../../assets/icons/user-patient.svg") no-repeat
                  center center;
              }
              &.hospital {
                background: url("../../assets/icons/user-hospital.svg")
                  no-repeat center center;
              }
            }
            .account-type-user-label {
              color: var(--ion-color-medium);
              &.isSelected {
                color: var(--ion-color-dark) !important;
              }
            }
          }
        }
      }
    }
    .account-type-next-button {
      filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
      width: 180px;
      height: 42px;
      align-self: center;
    }
  }
}
