.hospital-rep-card {
  width: 600px;
  .hospital-rep-card-header {
    display: flex;
    flex-direction: column;
    padding: 30px 30px 0px 30px;
  }
  .hospital-rep-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 30px 15px 30px;
    .hospital-rep-form-description-container {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
    }
    .hospital-rep-form-container-grid {
      width: 100%;
      margin-top: 10px;
      .hospital-rep-form-container-col {
        padding: 0 15px 30px 15px;
        &.last {
          padding-bottom: 25px;
        }
        .hospital-rep-form-password-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          --inner-padding-end: 0px;
          .hospital-rep-form-password-input {
            width: 100%;
            border: 1px solid var(--ion-color-medium);
            margin-top: 8px;
            --padding-start: 15px !important;
          }
          .hospital-rep-form-change-password-button {
            height: 30px;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }

  .hospital-rep-card-footer {
    display: flex;
    padding: 35px 30px 30px 30px;
    justify-content: space-between;
    .hospital-rep-cancel-button {
      --border-width: 1px;
      --border-style: solid;
      --border-color: var(--ion-color-medium);
      height: 30px;
      width: 100px;
    }
    .hospital-rep-submit-button {
      height: 30px;
      width: 100px;

      &.transfer {
        width: 120px;
      }

      &.cancel {
        width: 175px;
      }
    }
  }
}

a {
  cursor: pointer;
  color: #1b91ff !important;
}

@media (max-width: 414px) {
  .hospital-rep-card {
    width: 90%;
    .hospital-rep-card-header {
      padding: 15px 15px 0px 15px;
    }
    .hospital-rep-form-container {
      width: 100%;
      padding: 0px 15px 15px 15px;
      .hospital-rep-form-description-container {
        padding: 0 15px;
      }
      .hospital-rep-form-container-grid {
        width: 100%;
        .hospital-rep-form-container-col {
          padding: 0 0 15px;
          &.last {
            padding-bottom: 25px;
          }
          .patient-account-form-province-container {
            width: 100%;
          }
          .patient-account-form-preferred-doctor-container {
            width: 100%;
          }
        }
      }
    }

    .hospital-rep-card-footer {
      display: flex;
      flex-direction: column;
      padding: 35px 30px 30px 30px;
      justify-content: space-between;
      .hospital-rep-cancel-button {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--ion-color-medium);
        height: 30px;
        width: 100%;
      }
      .hospital-rep-submit-container {
        display: flex;
        flex-direction: column-reverse;
        .hospital-rep-submit-button {
          height: 30px;
          width: 100%;
        }
      }
    }
  }
}
