//TEXT STYLES
$wcHugeFontSize: 54px;
$wcHugeFontWeight: 500;
$wcHugeLineHeight: 63px;

$wcExtraHugeFontSize: 108px;
$wcExtraHugeFontWeight: 500;

$wcH1FontSize: 25px;
$wcH1FontBigSize: 32px;
$wcH1FontWeight: 700;
$wcH1LightFontWeight: 400;
$wcH1LineHeight: 34px;

$wcH2FontSize: 20px;
$wcH2FontWeight: 700;
$wcH2NormalFontWeight: 400;
$wcH2LineHeight: 27px;

$wcH3FontSize: 18px;
$wcH3FontWeight: 400;
$wcH3FontWeightBold: 700;
$wcH3LineHeight: 25px;

$wcH4FontSize: 16px;
$wcH4FontWeight: 500;
$wcH4NormalFontWeight: 400;
$wcH4FontWeightBold: 700;
$wcH4LineHeight: 19px;

$wcH5FontSize: 13px;
$wcH5FontWeight: 500;
$wcH5BoldFontWeight: 700;
$wcH5LineHeight: 15px;

$wcH6FontSize: 11px;
$wcH6SmallFontSize: 10px;
$wcH6XSmallFontSize: 8px;
$wcH6FontWeight: 400;
$wcH6BoldFontWeight: 700;
$wcH6LineHeight: 14px;

$wcBodyFontSize: 14px;
$wcBodyFontWeight: 400;
$wcBodyBoldFontWeight: 700;
$wcBodyLineHeight: 19px;

$wcParagraphFontSize: 12px;
$wcParagraphFontWeight: 400;
$wcParagraphFontWeightMedium: 500;
$wcParagraphFontWeightBold: 700;
$wcParagraphLineHeight: 16px;

$verifyEmailIconHeight: 108px;
$verifyEmailIconWidth: 108px;

$wcBlacklistDialogSpacing: 36px;

$wcCalendarDayFontSize: 20px;
$wcCalendarDayFontWeight: 400;
$wcCalendarDayLineHeight: 27px;
$wcCalendarWeekDayFontSize: 20px;
$wcCalendarWeekDayFontWeight: 700;
$wcCalendarWeekDayLineHeight: 27px;
