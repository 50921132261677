.patient-appointments-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.patient-appointments-view-container {
  height: 100%;
  width: 100%;
}
