.hospital-services-modal {
  --height: auto;
  --width: auto;
  --max-width: 817px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  --background: var(--ion-color-tertiary);
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 613px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 100%;
      .modal-content {
        overflow: auto;
      }
    }
  }
  .hospital-services-header {
    padding: 30px;
  }
  .hospital-services-setup-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    &.hidden {
      display: none;
    }
    .hospital-services-setup-form-content-container {
      padding: 20px;
      max-height: 600px;
      overflow-y: auto;
      .hospital-services-setup-form-container-grid {
        width: 100%;
        margin-bottom: 20px;
        &.footer {
          margin-bottom: 0;
        }

        .hospital-services-setup-form-container-col {
          padding-bottom: 30px;
          &.mobile {
            padding: 0px 0px 30px 0px !important;
          }
          .hospital-services-contact-number-input {
            padding-right: 10px;
          }
          .hospital-services-secondary-number-input {
            padding-left: 10px;
          }
        }
      }
      .estimated-dropdown-grid {
        .estimated-row {
          .hospital-services-setup-estimated-col {
            align-self: center;
            .hospital-services-setup-form-container-col-dropdown-container {
              width: 100% !important;
              .appointment-length-options {
                border: 1px solid var(--ion-color-medium);
                border-radius: 4px;
              }
            }
          }
        }
      }
      .hospital-services-setup-schedule-container {
        text-align: start;
        &.mobile {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
        .hospital-services-setup-schedule-item {
          .hospital-services-setup-schedule-label {
            margin-left: 15px;
          }
        }
        .hospital-services-time-slot-container {
          display: flex;
          flex-direction: row;
          .hospital-services-setup-time-slots-item {
            width: 180px;
            &.mobile {
              width: 120px !important;
            }
            .hospital-services-setup-time-slots-input {
              border: 1px solid var(--ion-color-medium);
              height: 23px;
              padding: 3px 5px 0px 5px;
            }
          }
          .hospital-services-setup-schedule-slots-item {
            width: 180px;
            &.mobile {
              width: 120px !important;
            }
            .hospital-services-setup-schedule-slots-input {
              border: 1px solid var(--ion-color-medium);
              height: 23px;
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
  .hospital-services-setup-button-container {
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .hospital-services-setup-back-button {
      border: 1px solid var(--ion-color-primary);
      border-radius: 5px;
      width: 100px;
    }
    .hospital-services-setup-save-button {
      border-radius: 5px;
      width: 100px;
    }
  }
}

a {
  cursor: pointer;
}
