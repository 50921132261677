.mb-input-item {
  display: flex;
  flex-direction: column;
  --background: var(--ion-color-tertiary);
  --inner-padding-end: 0;
  &.item-has-value {
    color: var(--ion-color-primary) !important;
    border-bottom: 1px solid var(--ion-color-primary);
    --border: unset;
  }
  .mb-input-label {
    margin-bottom: 10px !important;
    opacity: 1 !important;
    &.has-value {
      color: var(--ion-color-primary) !important;
    }
    &.has-focus {
      color: var(--ion-color-primary) !important;
    }
    &.has-error {
      color: var(--ion-color-danger) !important;
    }

    .mb-input-tooltip {
      width: 14px;
      height: 14px;
      background: url(../../assets/icons/information-icon.svg) no-repeat center
        center;
      margin-left: 9px;
      vertical-align: top;
    }
  }
  .mb-input {
    // --padding-start: 15px !important;
    &.text-area {
      min-height: 100px;

      .textarea-wrapper {
        textarea {
          min-height: 100px;
        }
      }
    }
    &.has-error {
      border-bottom: 1px solid var(--ion-color-danger) !important;
    }
    .dropdown-icon {
      position: absolute;
      right: 0;
      padding-right: 10px;
      color: var(--ion-color-medium);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .password-visibility {
    position: absolute;
    right: 0;
    padding-bottom: 5px;
  }
}
.mb-input-error-label {
  position: absolute;
  &.has-error {
    color: var(--ion-color-danger) !important;
  }
}
