.mb-detail-card {
  width: 671px;
  .mb-detail-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    .mb-detail-card-content-details-container {
      .mb-detail-card-grid-detail {
        margin-bottom: 20px;
        .mb-detail-card-row {
          .mb-detail-card-col-icons {
            display: flex;
            flex-direction: column;
            align-items: center;
            .mb-detail-card-details-icon {
              height: 171px;
              width: 171px;
              margin-bottom: 21px;
              &.service {
                background: url(../../assets/icons/detail-service.svg) no-repeat
                  center center;
              }
              &.ambulatory {
                background: url(../../assets/icons/detail-ambulatory.svg)
                  no-repeat center center;
              }
            }
            .mb-detail-card-status-container {
              background: var(--ion-color-success);
              border-radius: 5px;
              height: 25px;
              width: 155px;
              padding-top: 2px;
              &.achived {
                background: var(--ion-color-danger) !important;
              }
              &.not-available {
                background: rgba(196, 196, 196, 0.25) !important;
              }
            }
          }
          .mb-detail-card-col-details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .mb-detail-card-details-container {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .mb-detail-card-sub-detail-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .detail-container {
                  margin-top: 17px;
                  display: flex;
                  align-items: center;
                  .md-detail-card-sub-detail-icon {
                    height: 21px;
                    width: 21px;
                    margin-right: 12px;
                    &.hospital {
                      background: url(../../assets/icons/hospital-icon.svg)
                        no-repeat center center;
                    }
                    &.mobile {
                      background: url(../../assets/icons/mobile-icon.svg)
                        no-repeat center center;
                    }
                    &.location {
                      background: url(../../assets/icons/map-pin-icon.svg)
                        no-repeat center center;
                    }
                    &.fee {
                      background: url(../../assets/icons/fee-icon.svg) no-repeat
                        center center;
                    }
                  }
                }
              }
            }
          }
          .mb-detail-card-col-button {
            .mb-detail-card-action-button {
              --border-radius: 50%;
              height: 45px;
              width: 45px;
            }
          }
        }
      }
      .mb-detail-card-grid-schedule {
        margin-top: 20px;
        padding: 0px 40px;
        .mb-detail-card-row-schedule-header {
          margin-bottom: 20px;
        }
        .mb-detail-card-row-schedule-data {
          .mb-detail-card-col-schedule-day {
            display: flex;
            flex-direction: column;
            align-items: center;
            .mb-detail-card-schedule-day-container {
              background: rgba(87, 185, 198, 0.25);
              border-radius: 4px;
              height: 43px;
              width: 167px;
              padding-top: 6px;
              margin-bottom: 10px;

              &.not-available {
                background: rgba(196, 196, 196, 0.25) !important;
              }
            }
          }
          .mb-detail-card-col-schedule-time-slot {
            display: flex;
            flex-direction: column;
            align-items: center;
            .mb-detail-card-schedule-time-slot-label {
              height: 43px;
              width: 167px;
              padding-top: 6px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.mb-detail-card-popover {
  --box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.1);
  margin-top: 7px;
  --width: 6%;
  .popover-content {
    border-radius: 0px;
    background-color: var(--ion-color-tertiary);
    .mb-detail-card-list-container {
      display: flex;
      flex-direction: column;
      .mb-detail-card-list {
        background-color: var(--ion-color-tertiary);
        .mb-detail-card-item-container {
          width: 100%;
          background-color: var(--ion-color-tertiary) !important;
          .mb-detail-card-dropdown-modal-item {
            --inner-padding-end: 0;
            --padding-start: 0;
            --background: var(--ion-color-tertiary);
            .mb-detail-card-dropdown-label {
              display: flex;
              align-items: center;
              .mb-detail-card-dropdown-modal-item-icon {
                margin-left: 10px;
                margin-right: 10px;
                height: 21px;
                width: 21px;
                opacity: 0.2;
              }
            }
          }
        }
      }
    }
  }
}
