.mb-time-keeping-scan-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .mb-time-keeping-scan-logo {
    width: 268px;
    height: 200px;
    background: url(../../assets/qr-logo.svg) no-repeat center center;
  }
  .mb-time-keeping-scan-label {
    margin: 30px 0px 10px 0px;
  }
  .mb-time-keeping-scan-sub-label {
    margin-bottom: 21px;
  }
  .mb-time-keeping-scan-button {
    max-width: 152px;
    width: 100%;
    margin-top: 12px;
    height: 42px;
    filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
  }
}
.mb-qr-scanner-dialog {
  --height: 450px;
  --width: 100%;
  --max-width: 350px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  .modal-wrapper {
    max-width: 100%;
    width: 350px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      .modal-content {
        overflow: auto;
      }
    }
  }
  .mb-qr-scanner-container {
    max-height: 350px;
    width: 100%;
    height: 100%;
    .mb-qr-scanner {
    }
  }
  .mb-qr-scanner-cancel-button {
    align-self: center;
    margin-top: 35px;
    height: 42px;
    max-width: 145px;
    width: 100%;
    border: 1px solid var(--ion-color-primary);
    border-radius: 4px;
  }
}
