@import "../../theme/custom.scss";

.hospital-application-list {
  .hospital-application-list-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: none;
    border-radius: 10px;
    margin-bottom: 23px;
    width: 540px;
    --background: var(--ion-color-white);
    border: 1px solid var(--ion-color-medium);

    .hospital-application-list-card-content {
      width: 100%;
      .hospital-application-list-details-col-container {
        max-width: 200px;
        padding-left: 17px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 20px;
        .hospital-application-list-appointment-label {
          margin-bottom: 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-align: justify;
        }
        .hospital-application-list-doctor-hospital-label {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-align: justify;
          text-overflow: ellipsis;
        }
      }
      .hospital-application-list-button-col-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .hospital-application-list-view-button {
          margin-top: 8px;
          height: 30px;
          width: 100px;
          margin-bottom: 11px;
        }
        .hospital-application-list-cancel-button {
          height: 30px;
          width: 100px;
        }
      }
    }
  }
}

.hospital-application-list-no-schedule-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  .hospital-application-list-no-schedule-container {
    margin-top: 13px;
    align-self: center;
  }
}
.hospital-application-list-pagination-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .hospital-application-list-previous-icon {
    background: url(../../assets/icons/previous-icon.svg) no-repeat center;
    cursor: pointer;
    margin-right: 10px;
  }
  .hospital-application-list-next-icon {
    background: url(../../assets/icons/next-icon.svg) no-repeat center;
    cursor: pointer;
    margin-left: 10px;
  }
}

.mb-application-details-dialog {
  --height: auto;
  --width: auto;
  --max-width: 600px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  .modal-wrapper {
    max-width: 100%;
    width: 600px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media (max-width: 414px) {
  .hospital-application-list {
    width: 100% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    .hospital-application-list-card {
      width: 100%;
      margin-bottom: 10px !important;
      margin-top: 4px !important;
      .hospital-application-list-card-content {
        width: 100%;
        .hospital-application-list-details-col-container {
          padding-left: 10px !important;
          padding-right: 5px !important;
          .hospital-application-list-appointment-label {
            margin-bottom: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            text-align: justify;
          }
          .hospital-application-list-doctor-hospital-label {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-align: justify;
            text-overflow: ellipsis;
          }
        }
        .hospital-application-list-button-col-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .hospital-application-list-view-button {
            width: 55px !important;
          }
          .hospital-application-list-cancel-button {
            height: 30px;
            width: 100px;
          }
        }
      }
    }
  }
}
