.bkg-gray {
  background-color: #fafafa;
  .preregistration {
    padding: 20px 60px !important;
    box-shadow: -1px 3px 5px 0px rgba(61, 61, 61, 0.29);
    border-radius: 20px;
    min-width: 380px;
  }

  .gray {
    background-color: #fafafa;
  }

  .preregister-icon {
    font-size: 20px;
    opacity: 0.5;
  }

  .logo-padding {
    padding-left: 40px;
  }
}
