.reset-password-form-card {
  max-width: 529px;
  max-height: 608px;
  max-width: 529px;
  width: 100%;
  &.mobile {
    width: 100%;
  }

  .reset-password-form-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 35px 15px;
    .reset-password-form-card-logo {
      background: url(../../assets/mb-card-logo.svg) no-repeat center;
      width: 396px;
      height: 100px;
      margin-bottom: 12px;
      align-self: center;
      &.mobile {
        // background: url(../../assets/mb-card-logo-mobile.svg) no-repeat center;
        // width: 240px;
        // height: 90px;
        // margin: 0 auto 12px;
        margin: 0 auto 12px;
        width: 100%;
      }
    }
    .reset-password-form-details {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0 15px;
      .reset-password-info-container {
        display: flex;
        flex-direction: row;
        margin-top: 25px;
        align-items: center;
        justify-content: center;
        .reset-password-info {
          background: url(../../assets/icons/info.svg) no-repeat center;
          height: 47px;
          width: 47px;
        }
        .reset-password-description {
          width: 70%;
          text-align: left !important;
          margin-left: 15px;
        }
      }

      .reset-password-form-input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        .reset-password-form-email-input {
          margin-top: 30px;
          max-width: 416px;
          width: 100%;
        }
      }
      .reset-password-form-send-button {
        max-width: 420px;
        width: 100%;
        margin-top: 20px;
        height: 43px;
        filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
      }
    }
  }
}
