.mb-appointment-card {
  width: 600px;
  &.support-hospital-card {
    width: 900px;
  }
  .mb-appointment-card-header {
    display: flex;
    flex-direction: column;
    padding: 30px 30px 15px 30px;
    .mb-card-header-logo {
      position: absolute;
      right: 0;
      top: 0;
      &.magsikilos {
        margin: 20px 30px;
        height: 80px;
        width: 135px;
        background: url("../../assets/transport-magsikilos-logo.png") no-repeat
          center center;

        &.mobile {
          height: 52px;
          width: 88px;
          background-size: contain;
        }
      }
    }
  }
  .mb-appointment-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    .mb-content-container {
      padding: 30px 15px 30px 15px;
    }
    .mb-appointment-card-footer {
      display: flex;
      flex-direction: column;
      padding: 35px 30px 23px 30px;
      justify-content: center;

      .mb-appointment-card-footer-main-buttons {
        display: flex;
        justify-content: space-evenly;
        &.mobile {
          flex-direction: column;
        }
        .mb-appointment-card-set-appointment-button {
          height: 30px;
          width: 150px;
          border: 1px solid var(--ion-color-medium);
          border-radius: 5px;
          &.mobile {
            width: 100%;
          }

          .clear-schedule {
            --color: red;
            border: none;
          }
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .mb-appointment-card {
    width: 100% !important;
  }

  .mb-content-container {
    padding: 15px !important;
  }
}
