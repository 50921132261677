@import "../../theme/shared.scss";

.mb-calendar-range-dialog {
  --height: auto;
  --width: auto;
  --max-width: 500px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  --background: var(--ion-color-tertiary);
  .modal-wrapper {
    max-width: 100%;
    width: 360px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .mb-calendar-range-dialog-children-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px;
    .mb-calendar-range-dialog-icon {
      height: 50px;
      width: 50px;
      &.success {
        background: url("../../assets/icons/success.svg") no-repeat center
          center;
      }
      &.warning {
        background: url("../../assets/icons/warning.svg") no-repeat center
          center;
      }
      &.transportation {
        background: url("../../assets/icons/transportation-logo.svg") no-repeat
          center center;
        height: 67px;
        width: 67px;
      }
      &.no-transportation {
        background: url("../../assets/icons/transportation-none-logo.svg")
          no-repeat center center;
        height: 67px;
        width: 67px;
      }
      &.download {
        background: url("../../assets/icons/download.svg") no-repeat center
          center;
        height: 67px;
        width: 67px;
      }
    }
    .mb-calendar-range-dialog-title-label {
      margin-top: 15px;
      margin-bottom: 15px;
      text-align: center;
    }
    .mb-calendar-range-dialog-message-label {
      text-align: center;
    }

    .mb-calendar-range-dialog-radio-container {
      width: 100%;
      .mb-calendar-radio-range {
        margin-right: 10px !important;
      }
    }

    .mb-calendar-range-dialog-dates-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .mb-calendar-range-dialog-options-container {
      display: flex;
      padding-top: 30px;
      justify-content: space-between;
      width: 100%;
      .mb-calendar-range-dialog-no-button {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--ion-color-medium);
        height: 30px;
        width: 100px;
      }
      .mb-calendar-range-dialog-submit-button {
        height: 30px;
        width: 100px;
      }
    }
    .mb-calendar-range-dialog-action-button {
      height: 30px;
      width: 98px;
      margin-top: 55px;
    }
  }
}
