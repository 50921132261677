.tab-container {
  ion-content ion-toolbar {
    --background: translucent;
  }

  .lime {
    background-color: #57c6aa !important;
  }
  .infoicon {
    font-size: 4px !important;
  }
}
