.mb-file-input-item {
  display: flex;
  flex-direction: column;
  --background: var(--ion-color-white);
  --inner-padding-end: 0;
  .mb-file-input-label {
    margin-bottom: 10px !important;
    opacity: 1 !important;
    &.has-error {
      color: var(--ion-color-danger) !important;
    }
  }
  .mb-file-input-button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .mb-file-input {
      width: 100%;
      border: 1px solid var(--ion-color-medium);
      margin-bottom: 4px;
      text-overflow: ellipsis;
      --padding-start: 15px !important;
      --padding-end: 60px;

      &.has-error {
        border: 1px solid var(--ion-color-danger) !important;
      }

      input.native-input[disabled] {
        opacity: 1 !important;
      }
    }
    .mb-file-input-upload-button {
      height: 37px;
      --border-radius: 2px;
      border: 1px solid var(--ion-color-primary) !important;
    }
  }
}
.mb-file-input-error-label {
  position: absolute;
  &.has-error {
    color: var(--ion-color-danger) !important;
  }
}
