@import "../../theme/shared.scss";

.covid-checklist-dialog {
  --height: auto;
  --width: auto;
  --max-width: 600px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  --background: var(--ion-color-tertiary);
  .modal-wrapper {
    max-width: 100%;
    width: 600px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .covid-checklist-dialog-children-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 0px;
    height: auto;
    .covid-checklist-dialog-title-label {
      margin-top: 15px;
      padding: 0px 30px;
    }
    .covid-checklist-slides {
      width: 100%;
      .covid-checklist-dialog-landing-slide {
        width: 100% !important;
        margin-top: 5px;
        padding: 0px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .codvid-checklist-landing-container {
          padding: 20px 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .covid-landing-logo {
            height: 231px;
            width: 258px;
            background: url("../../assets/covid-landing-logo.svg") no-repeat
              center center;
          }
        }
      }
      .covid-checklist-dialog-exposure-slide {
        width: 100% !important;
        margin-top: 5px;
        padding: 0px 30px;
        flex-direction: column;
        align-items: flex-start;
        .covid-checklist-dialog-message-label {
          text-align: left;
          margin-bottom: 16px;
          width: 100% !important;
        }
        .covid-checklist-sub-label {
          align-self: flex-start;
          margin-bottom: 18px;
        }
        .yes-no-input-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          .yes-no-radio-group-container {
            width: 310px;
          }
          .covid-checklist-travel-history-input {
            height: 24px;
            width: 30%;
            border: 1px solid var(--ion-color-medium);
            position: absolute;
            left: 0;
            margin-top: 10px;
            margin-left: 50%;
            text-align: left;
            &.mobile {
              margin-left: 195px;
            }
          }
        }
        .codvid-checklist-exposure-questions-container {
          margin: 18px, 30px;
          width: 100% !important;
          .covid-checklist-exposure-questions-choices-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .covid-checklist-questions-label {
              text-align: left;
            }
            .covid-checklist-questions-sub-label {
              text-align: left;
            }
            .covid-checklist-date-test-container {
              position: absolute;
              right: 50%;
              margin-top: 15px;

              .covid-checklist-item-container {
                display: flex;
                flex-direction: row;
                .input-label {
                  margin-right: 5px;
                }
                .covid-checklist-date-test-input {
                  height: 24px;
                  width: 70px;
                  border: 1px solid var(--ion-color-medium);
                  text-align: left;
                }
              }
            }
          }
        }
      }
      .covid-checklist-symptoms-slide {
        width: 100% !important;
        padding: 0px 30px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        .covid-checklist-symptoms-message-label {
          text-align: left;
          margin-bottom: 31px;
        }
        .covid-checklist-symptoms-sub-title-label {
          align-self: flex-start;
          margin-bottom: 18px;
        }
        .covid-checklist-symptoms-options-container {
          width: 100% !important;
          display: flex;
          flex-direction: column;
          align-items: center;
          .yes-no-radio-group-container {
            width: 100%;
          }
        }
      }
    }
  }
  .covid-checklist-dialog-buttons-container {
    display: flex;
    padding: 30px 30px 0 30px;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    margin-bottom: 30px;
    .covid-checklist-dialog-back-button {
      --border-width: 1px;
      --border-style: solid;
      --border-color: var(--ion-color-medium);
      height: 30px;
      width: 100px;
    }
    .covid-checklist-dialog-next-button {
      height: 30px;
      width: 100px;
    }
  }
}
