.mb-input-container {
  --inner-padding-end: 0;
  &.item-has-value {
    color: var(--ion-color-primary) !important;
    border-bottom: 1px solid var(--ion-color-primary) !important;
    // --padding-bottom: 11px;
  }
  &.item-has-error {
    color: var(--ion-color-danger) !important;
    border-bottom: 1px solid var(--ion-color-danger) !important;
    // --padding-bottom: 11px;
  }
  .mb-date-input-label {
    margin-bottom: 10px !important;
    margin-top: 8px;
    &.has-focus {
      color: var(--ion-color-primary) !important;
    }
  }
  .mb-date-input {
    --padding-start: 15px !important;
    padding-bottom: 6px !important;
    &.no-value {
      color: var(--ion-color-light) !important;
    }
  }
  .mb-input-icon {
    opacity: 0.2;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 0;
    bottom: 2px;
    margin-right: 10px;
  }
}

.mb-date-input-error-label {
  position: absolute;
  &.has-error {
    color: var(--ion-color-danger) !important;
  }
}
