.doctor-home-dashboard-card-container {
  display: flex;
  flex-direction: row;
}
.doctor-home-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  .doctor-home-icon {
    background: url("../../assets/doctor-home-logo.svg") no-repeat center center;
    width: 399px;
    height: 274px;
  }
  .exec-assistant-home-icon {
    background: url("../../assets/executive-assistant-home-logo.svg") no-repeat
      center center;
    width: 307px;
    height: 211px;
  }

  .doctor-home-title {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.doctor-home-button-container {
  margin-top: 25px;
  .doctor-home-goto-button {
    width: 200px;
    height: 42px;
    border-radius: 5px;
  }
  .doctor-home-add-doctor-services {
    width: 375px;
    height: 42px;
  }
}
