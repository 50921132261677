@import "../../theme/shared.scss";

.mb-view-appointment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .mb-appointment-list-grid {
    height: 100%;
    .mb-appointment-list-row {
      height: 100%;
      .mb-appointment-list-icon-col {
        height: 100%;
        .mb-appointment-list-icon-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          .mb-appointment-list-card-icon {
            margin-bottom: 25px;
            &.patient {
              background: url("../../assets/icons/patient-icon.svg") no-repeat
                center center;
              width: 70px;
              height: 71px;
            }
            &.doctor {
              background: url("../../assets/icons/doctor-card-icon.svg")
                no-repeat center center;
              width: 58px;
              height: 58px;
            }
            &.out-patient {
              background: url("../../assets/icons/ambulatory-card-icon.svg")
                no-repeat center center;
              width: 59px;
              height: 59px;
            }
          }
          .mb-appointment-list-status {
            min-height: 25px;
            max-width: 115px;
            border-radius: 4px;
            width: 100%;
            font-size: $wcH4FontSize;
            font-weight: 700;
            text-align: center;
            padding-top: 2px;
            &.booked {
              color: var(--ion-color-warning);
              background-color: rgba(#ffa403, 0.25);
            }
            &.today {
              color: var(--ion-color-primary);
              background-color: rgba(#57b9c6, 0.25);
            }
            &.cancelled {
              color: var(--ion-color-danger);
              background-color: rgba(#cc212a, 0.25);
            }
            &.done {
              color: var(--ion-color-success);
              background-color: rgba(#57c6aa, 0.25);
            }
          }
        }
      }
      .mb-appointment-list-details-col {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        .mb-appointment-list-patient-name {
        }
        .mb-appointment-list-hospital-container {
          margin: 5px 0px;
        }
        .mb-appointment-list-schedule-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          .mb-appointment-list-date-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .mb-appointment-list-date-label {
              padding-top: 2px;
              margin: 0px 10px;
            }
          }
          .mb-appointment-list-time-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .mb-appointment-list-time-label {
              padding-top: 2px;
              margin: 0px 10px;
            }
          }
        }
        .mb-appointment-list-fee-label {
          margin-top: 30px;
        }
      }
      .mb-appointment-list-button-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding-right: 20px;
        .mb-appointment-list-button-container {
          .mb-appointment-list-button {
            border-radius: 4px;
            min-height: 43px;
            min-width: 93px;
            margin: 0px 3px;
            &.cancel {
              border: 1px solid var(--ion-color-danger);
            }
            &.view {
              border: 1px solid var(--ion-color-primary);
            }
          }
        }
      }
    }
  }
}
