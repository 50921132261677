.doctor-account-detail-card {
  width: 671px;
  height: auto;
  .doctor-account-detail-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    .doctor-account-detail-card-content-details-container {
      .doctor-account-detail-card-grid-detail {
        margin-bottom: 10px;
        .doctor-account-detail-card-row {
          margin-bottom: 30px;
          .doctor-account-detail-card-col-icons {
            display: flex;
            flex-direction: column;
            align-items: center;
            .doctor-account-detail-card-details-icon {
              height: 171px;
              width: 171px;
              margin-bottom: 21px;
              background: url(../../assets/icons/detail-doctor-resource-big.svg)
                no-repeat center center;
            }
          }
          .doctor-account-detail-card-col-details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .doctor-account-detail-card-details-container {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              width: 100%;
              .doctor-account-detail-card-sub-detail-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                .doctor-hospital-dropdown-container {
                  margin-top: 5px;
                  width: 100%;
                }
                .detail-container {
                  margin-top: 17px;
                  display: flex;
                  align-items: center;
                  &.fee-container {
                    margin-top: 5px;
                  }
                  .md-detail-card-sub-detail-icon {
                    height: 21px;
                    width: 21px;
                    margin-right: 12px;
                    &.gender {
                      background: url(../../assets/icons/gender.svg) no-repeat
                        center center;
                    }
                    &.birthday {
                      background: url(../../assets/icons/birthday.svg) no-repeat
                        center center;
                    }
                    &.mail {
                      background: url(../../assets/icons/mail-icon.svg)
                        no-repeat center center;
                    }
                    &.mobile {
                      background: url(../../assets/icons/mobile-icon.svg)
                        no-repeat center center;
                    }
                    &.freebies {
                      background: url(../../assets/icons/freebies.svg) no-repeat
                        center center;
                    }
                  }
                }
              }
            }
          }
          .doctor-account-detail-card-col-button {
            .doctor-account-detail-card-action-button {
              --border-radius: 50%;
              height: 45px;
              width: 45px;
            }
          }
        }
        .doctor-account-detail-speciality-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
        }
      }
      .doctor-account-detail-card-grid-schedule {
        margin-top: 20px;
        padding: 0px 40px;
        .doctor-account-detail-card-row-schedule-header {
          margin-bottom: 20px;
        }
        .doctor-account-detail-card-row-schedule-data {
          .doctor-account-detail-card-col-schedule-day {
            display: flex;
            flex-direction: column;
            align-items: center;
            .doctor-account-detail-card-schedule-day-container {
              background: rgba(87, 185, 198, 0.25);
              border-radius: 4px;
              height: 43px;
              width: 167px;
              padding-top: 6px;
              margin-bottom: 10px;
              &.not-available {
                background: rgba(196, 196, 196, 0.25) !important;
              }
            }
          }
          .doctor-account-detail-card-col-schedule-time-slot {
            display: flex;
            flex-direction: column;
            align-items: center;
            .doctor-account-detail-card-schedule-time-slot-label {
              height: 43px;
              width: 167px;
              padding-top: 6px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.doctor-account-assistants-card {
  max-width: 671px;
  width: 100%;
  background: var(--ion-color-tertiary);
  margin-top: 10px !important;
  .doctor-account-assistants-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    border-bottom: 1px solid var(--ion-color-medium);
    .doctor-account-assistants-card-add-button {
      height: 30px;
    }
  }

  .doctor-account-assistants-card-content {
    padding: 25px;
    .doctor-account-assistants-card-col {
      display: flex;
      align-items: center;

      &.button {
        justify-content: flex-end;
      }
    }
  }
}

.doctor-account-detail-card-popover {
  --box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.1);
  margin-top: 7px;
  --width: 10%;
  .popover-content {
    border-radius: 0px;
    background-color: var(--ion-color-tertiary);
    .doctor-account-detail-card-list-container {
      display: flex;
      flex-direction: column;
      .doctor-account-detail-card-list {
        background-color: var(--ion-color-tertiary);
        .doctor-account-detail-card-item-container {
          width: 100%;
          background-color: var(--ion-color-tertiary) !important;
          .doctor-account-detail-card-dropdown-modal-item {
            --inner-padding-end: 0;
            --padding-start: 0;
            --background: var(--ion-color-tertiary);
            .doctor-account-detail-card-dropdown-label {
              display: flex;
              align-items: center;
              .doctor-account-detail-card-dropdown-modal-item-icon {
                margin-left: 10px;
                margin-right: 10px;
                height: 21px;
                width: 21px;
                opacity: 0.2;
              }
            }
          }
        }
      }
    }
  }
}
