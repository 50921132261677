.patient-home-search-card-container {
  display: flex;
  align-items: center;
  background: var(--ion-color-tertiary);
  width: 100%;
  padding: 31px 25px 35px;
  overflow-y: hidden;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .patient-home-search-input {
    margin: 0 3px;
    --background: var(--ion-color-tertiary);
    border-radius: 4px;
    border: 1px solid var(--ion-color-medium);
    height: 41px;
  }
  .patient-home-search-card-col-dropdown-container {
    margin: 0 3px;
    width: 250px;
    .patient-home-hospital-drop-down-options {
      border: 1px solid var(--ion-color-medium);
      border-radius: 4px;
      margin: 0;
      height: 100%;
      --padding-start: 10px !important;
      --padding-end: 10px !important;
    }
  }
  .patient-home-search-submit {
    margin: 0 3px;
    width: 100%;
    max-width: 104px;
    filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
  }
}

.patient-home-resource-results {
  display: flex;
  flex-direction: column;
  margin-top: 27px;
  align-content: flex-start;
  width: 100%;

  .patient-home-results-label {
    opacity: 0.5;
    margin-bottom: 5px;
  }

  .patient-home-resource-card {
    background: var(--ion-color-tertiary);
    padding: 25px;
    margin-top: 20px;
    .patient-home-resource-card-content {
      display: flex;
      height: 100%;
      .patient-home-resource-card-doctor-icon {
        height: 101px;
        width: 101px;
        padding: 0 32px 0 20px;
        background: url(../../assets/icons/detail-doctor-resource.svg) no-repeat
          center center;
      }

      .patient-home-resource-card-service-icon {
        height: 101px;
        width: 101px;
        padding: 0 32px 0 20px;
        background: url(../../assets/icons/detail-service-resource.svg)
          no-repeat center center;
      }

      .patient-home-resource-details {
        display: flex;
        flex-direction: column;
        width: 100%;
        .patient-home-resource-hospital {
          margin-top: 5px;

          .patient-home-detail-resource-type {
            opacity: 0.5;
          }
        }

        .patient-home-detail-schedule-days-container {
          margin-top: 14px;
        }

        .patient-home-resource-fee {
          margin-top: 20px;
        }
      }

      .patient-home-resource-view-button-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .patient-home-resource-distance-label {
          opacity: 0.4;
        }
        .patient-home-resource-view-button {
          width: 94px;
        }
      }
    }
  }
}
.patient-home-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  .patient-home-icon {
    background: url("../../assets/patient-home-logo.svg") no-repeat center
      center;
    width: 279px;
    height: 272px;
  }
  .patient-home-title {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
