.time-keeping-modal {
  --height: auto;
  --width: auto;
  --max-width: 817px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  --background: var(--ion-color-tertiary);
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 613px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 100%;
      .modal-content {
        overflow: auto;
      }
    }
  }
  .time-keeping-header {
    padding: 30px;
  }
  .time-keeping-setup-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    &.hidden {
      display: none;
    }
    .time-keeping-setup-form-content-container {
      padding: 20px 40px 0px 40px;
      max-height: 600px;
      overflow-y: auto;
      .time-keeping-setup-form-container-grid {
        width: 100%;
        &.footer {
          margin-bottom: 0;
        }
        .time-keeping-setup-form-container-col {
          padding-bottom: 25px;
          &.mobile {
            padding: 0px 0px 30px 0px !important;
          }
          .time-keeping-contact-number-input {
            padding-right: 10px;
          }
          .time-keeping-secondary-number-input {
            padding-left: 10px;
          }
          .time-keeping-form-password-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            --inner-padding-end: 0px;
            .time-keeping-form-password-input {
              width: 100%;
              border: 1px solid var(--ion-color-medium);
              margin-top: 8px;
              --padding-start: 15px !important;
            }
            .time-keeping-form-change-password-button {
              height: 30px;
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }
  }
  .time-keeping-footer-note-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(#57b9c6, 0.25);
    justify-content: center;
    height: 87px;
    margin-bottom: 20px;
    .info-icon {
      background: url("../../assets/icons/info-icon.svg") no-repeat center
        center;
      height: 37px;
      width: 37px;
    }
    .time-keeping-info-note {
      max-width: 415px;
      width: 100%;
      margin-left: 20px;
    }
  }
  .time-keeping-setup-button-container {
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .time-keeping-setup-back-button {
      border: 1px solid var(--ion-color-primary);
      border-radius: 5px;
      width: 100px;
    }
    .time-keeping-setup-save-button {
      border-radius: 5px;
      width: 100px;
    }
  }
}

a {
  cursor: pointer;
}
