.executive-account-form-modal {
  --height: auto;
  --width: auto;
  --max-width: 817px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  --background: var(--ion-color-tertiary);
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 613px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 100%;
      .modal-content {
        overflow: auto;
      }
    }
  }
  .executive-account-form-header {
    padding: 25px;
  }
  .executive-account-form-setup-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 600px;
    padding: 0px 35px 20px 35px;
    overflow-y: auto;
    &.hidden {
      display: none;
    }
    .executive-account-form-setup-form-description-container {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      &.mobile {
        padding: 0px !important;
      }
    }
    .executive-account-form-setup-form-container-grid {
      width: 100%;
      margin-top: 30px;
      &.footer {
        margin-bottom: 0;
      }
      .executive-account-form-setup-form-container-col {
        padding-bottom: 30px;
        &.mobile {
          padding: 0px 0px 30px 0px !important;
        }

        .executive-account-form-setup-form-select-container {
          width: 100%;
        }
      }
    }

    .executive-account-form-setup-break-time-schedule,
    .executive-account-form-setup-schedule-estimated-appointment-length,
    .executive-account-form-setup-schedule-container {
      margin-left: 15px;
      margin-right: 15px;
      text-align: start;

      &.executive-account-form-setup-break-time-schedule {
        margin-bottom: 40px;
      }

      &.executive-account-form-setup-schedule-estimated-appointment-length {
        margin-bottom: 40px;
      }

      &.mobile {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
      .executive-account-form-setup-row-schedule {
        &.mobile {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      .executive-account-form-setup-schedule-item {
        width: 140px;
        &.mobile {
          width: 105px;
        }
        .executive-account-form-setup-schedule-label {
          margin-left: 17px;
        }
      }

      .executive-account-form-setup-schedule-col {
        align-self: center;
        padding-right: 20px;

        .appointment-length-options {
          border: 1px solid var(--ion-color-medium);
          border-radius: 4px;
        }

        .executive-account-form-setup-time-slot-input {
          height: 38px;
          border: 1px solid var(--ion-color-medium-shade);
          border-radius: 4px;
          align-items: center;
          padding-left: 10px;
        }
      }

      // .executive-account-form-setup-schedule-period-container {
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: space-evenly;
      //   .executive-account-form-setup-schedule-slots-item {
      //     margin-bottom: 2px;
      //     margin-right: 3px;
      //     --inner-padding-end: 0;
      //     .executive-account-form-setup-schedule-slots-input {
      //       border: 1px solid var(--ion-color-medium);
      //       height: 23px;
      //       width: 45px !important;
      //       margin-left: 10px;
      //     }
      //   }
      // }
    }
  }
  .executive-account-form-setup-button-container {
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .executive-account-form-setup-back-button {
      border: 1px solid var(--ion-color-primary);
      border-radius: 5px;
      width: 100px;
    }
    .executive-account-form-setup-save-button {
      border-radius: 5px;
      width: 100px;
    }
  }
}

.doctor-specialities-dialog {
  --height: auto;
  --width: auto;
  --max-width: 500px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  --background: var(--ion-color-tertiary);
  .modal-wrapper {
    max-width: 100%;
    width: 500px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      .modal-content {
        overflow: auto;
      }
    }
  }
  .doctor-specialities-dialog-children-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    .doctor-specialities-title-label {
      align-self: flex-start;
      margin-bottom: 5px;
    }
    .doctor-specialities-dropdown-multiple-container {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      .doctor-specialitiest-chip {
        --border-radius: 5px;
        padding: 10px;
      }
      .doctor-specialities-dialog-dropdown-container-container {
        width: 438px;
      }
    }
  }
  .doctor-specialities-dialog-footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 30px;
    .doctor-specialities-dialog-back-button {
      --border-width: 1px;
      --border-style: solid;
      --border-color: var(--ion-color-primary);
      height: 36px;
      width: 100px;
    }
    .doctor-specialities-dialog-submit-button {
      height: 36px;
      width: 100px;
    }
  }
}
