.hospital-appointments-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.hospital-appointments-view-container {
  height: 100%;
  width: 100%;
}

.hospital-job-well-done-dialog {
  --height: auto;
  --width: auto;
  --max-width: 500px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  --background: var(--ion-color-tertiary);
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 500px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .hospital-job-well-done-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
    text-align: center;
    .hospital-job-well-done-label {
      margin-top: 5px;
    }
    .hospital-job-well-done-icon {
      background: url("../../assets/thank-you-job-well-done.svg") no-repeat
        center center;
      height: 164px;
      width: 230px;
      margin: 20px 0px;
    }
    .hospital-job-well-done-button {
      width: 100%;
      margin-top: 30px;
      filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
    }
  }
}

// .hospital-appointments-header {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
//   margin-bottom: 21px;
//   .hospital-appointments-header-date-label {
//     cursor: pointer;
//   }
//   .hospital-appointments-header-previous-icon {
//     background: url(../../assets/icons/previous-icon.svg) no-repeat center;
//     cursor: pointer;
//     margin-right: 10px;
//   }
//   .hospital-appointments-header-next-icon {
//     background: url(../../assets/icons/next-icon.svg) no-repeat center;
//     cursor: pointer;
//     margin-left: 10px;
//   }
// }

// .hospital-appointments-footer {
//   display: flex;
//   flex-direction: column;
//   padding: 35px 30px 0px 30px;
//   justify-content: center;

//   .hospital-appointments-footer-main-buttons {
//     display: flex;
//     justify-content: space-evenly;
//     .hospital-appointments-download-button {
//       height: 30px;
//       width: 150px;
//       border-radius: 5px;
//     }
//   }
// }
