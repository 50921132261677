@import "./shared.scss";

* {
  letter-spacing: -0.2px !important;
}

.landing-light {
  background: #f4f5f8 !important;
  --background: #f4f5f8 !important;
  .black {
    --color: black !important;
  }
}
.landing-text-color {
  --color: white !important;
}
.card-no-shadow {
  box-shadow: 0 0 0 !important;
}
.landing-center {
  margin: auto;
}

//TEXT STYLES
.mb-huge {
  font-size: $wcHugeFontSize !important;
  font-weight: $wcHugeFontWeight !important;
  color: var(--ion-color-dark) !important;
  line-height: $wcHugeLineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
}

.mb-extra-huge {
  font-size: $wcExtraHugeFontSize !important;
  font-weight: $wcExtraHugeFontWeight !important;
  color: var(--ion-color-dark) !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
}

.mb-h1 {
  font-size: $wcH1FontSize !important;
  font-weight: $wcH1FontWeight !important;
  color: var(--ion-color-dark) !important;
  line-height: $wcH1LineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
  &.blue {
    color: var(--ion-color-primary) !important;
  }
  &.dark-blue {
    color: var(--ion-color-secondary) !important;
  }
  &.light-weight {
    font-weight: $wcH1LightFontWeight !important;
  }
  &.big {
    font-size: $wcH1FontBigSize !important;
  }
}

.mb-h2 {
  font-size: $wcH2FontSize !important;
  font-weight: $wcH2FontWeight !important;
  color: var(--ion-color-dark) !important;
  line-height: $wcH2LineHeight !important;
  &.primary {
    color: var(--ion-color-primary) !important;
  }
  &.normal {
    font-weight: $wcH2NormalFontWeight !important;
  }
  &.white {
    color: var(--ion-color-white) !important;
  }
  &.light {
    color: var(--ion-color-light) !important;
  }

  &.blue {
    color: var(--ion-color-primary) !important;
  }
  &.dark-blue {
    color: var(--ion-color-secondary) !important;
  }
  &.bold {
    font-weight: $wcBodyBoldFontWeight !important;
  }
}

.mb-h3 {
  font-size: $wcH3FontSize;
  font-weight: $wcH3FontWeight !important;
  color: var(--ion-color-dark) !important;
  line-height: $wcH3LineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
  &.blue {
    color: var(--ion-color-tertiary) !important;
  }
  &.dark-blue {
    color: var(--ion-color-secondary) !important;
  }
  &.violet {
    color: var(--ion-color-primary) !important;
  }
  &.bold {
    font-weight: $wcH3FontWeightBold !important;
  }
}

.mb-h4 {
  font-size: $wcH4FontSize !important;
  font-weight: $wcH4FontWeight !important;
  color: var(--ion-color-dark) !important;
  --color: var(--ion-color-dark) !important;
  line-height: $wcH4LineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
  &.blue {
    color: var(--ion-color-primary) !important;
  }
  &.dark-blue {
    color: var(--ion-color-secondary) !important;
  }
  &.success {
    color: var(--ion-color-success) !important;
  }
  &.danger {
    color: var(--ion-color-danger) !important;
  }
  &.light {
    color: var(--ion-color-light) !important;
  }

  &.bold {
    font-weight: $wcH4FontWeightBold !important;
  }
  &.normal {
    font-weight: $wcH4NormalFontWeight !important;
  }
  &.opaque {
    opacity: 0.5;
  }
}

.mb-h5 {
  font-size: $wcH5FontSize !important;
  font-weight: $wcH5FontWeight !important;
  color: var(--ion-color-dark) !important;
  line-height: $wcH5LineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
  &.blue {
    color: var(--ion-color-primary) !important;
  }
  &.danger {
    color: var(--ion-color-danger) !important;
  }
  &.light {
    color: var(--ion-color-light) !important;
  }

  &.bold {
    font-weight: $wcH5BoldFontWeight !important;
  }
}

.mb-h6 {
  font-size: $wcH6FontSize !important;
  font-weight: $wcH6FontWeight !important;
  color: var(--ion-color-light) !important;
  line-height: $wcH6LineHeight !important;
  &.blue {
    color: var(--ion-color-primary) !important;
  }
  &.small {
    font-size: $wcH6SmallFontSize !important;
  }
  &.xsmall {
    font-size: $wcH6XSmallFontSize !important;
  }
  &.white {
    color: var(--ion-color-white) !important;
  }

  &.dark {
    color: var(--ion-color-dark) !important;
  }

  &.medium-dark {
    color: var(--ion-color-medium-dark) !important;
  }

  &.bold {
    font-weight: $wcH6BoldFontWeight !important;
  }
}

.mb-body {
  font-size: $wcBodyFontSize !important;
  font-weight: $wcBodyFontWeight !important;
  color: var(--ion-color-dark) !important;
  --color: var(--ion-color-dark) !important;
  line-height: $wcBodyLineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
    --color: var(--ion-color-white) !important;
  }
  &.blue {
    color: var(--ion-color-primary) !important;
    --color: var(--ion-color-primary) !important;
  }
  &.dark-blue {
    color: var(--ion-color-secondary) !important;
    --color: var(--ion-color-secondary) !important;
  }
  &.light {
    color: var(--ion-color-light) !important;
    --color: var(--ion-color-light) !important;
  }
  &.dark {
    color: var(--ion-color-dark) !important;
    --color: var(--ion-color-dark) !important;
  }
  &.medium-dark {
    color: var(--ion-color-medium-dark) !important;
    --color: var(--ion-color-medium-dark) !important;
  }
  &.medium {
    color: var(--ion-color-medium) !important;
    --color: var(--ion-color-medium) !important;
  }
  &.medium-light {
    color: var(--ion-color-medium-dark) !important;
    --color: var(--ion-color-medium-dark) !important;
    opacity: 0.7;
  }
  &.danger {
    color: var(--ion-color-danger) !important;
    --color: var(--ion-color-danger) !important;
  }
  &.warning {
    color: var(--ion-color-warning) !important;
    --color: var(--ion-color-warning) !important;
  }
  &.success {
    color: var(--ion-color-success) !important;
    --color: var(--ion-color-success) !important;
  }
  &.red {
    color: red !important;
    --color: red !important;
  }
  &.bold {
    font-weight: $wcBodyBoldFontWeight !important;
  }
  &.opaque {
    opacity: 0.5;
  }
}

.mb-paragraph {
  font-size: $wcParagraphFontSize !important;
  font-weight: $wcParagraphFontWeight !important;
  color: var(--ion-color-dark) !important;
  line-height: $wcParagraphLineHeight !important;

  &.white {
    color: var(--ion-color-white) !important;
  }
  &.light {
    color: var(--ion-color-light) !important;
  }
  &.blue {
    color: var(--ion-color-primary) !important;
  }
  &.primary {
    color: var(--ion-color-primary) !important;
  }

  &.danger {
    color: var(--ion-color-danger) !important;
  }
  &.medium {
    font-weight: $wcParagraphFontWeightMedium !important;
  }
  &.bold {
    font-weight: $wcParagraphFontWeightBold !important;
  }
}

//Content Background
.doctor-background-image {
  --background: url(../assets/landing_page_bg.png) no-repeat center center /
    cover;
}

.login-image {
  --background: url(../assets/login-img.svg) no-repeat center center / cover;
}

.noselect {
  // pointer-events: none;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;

  &:focus {
    outline: none !important;
  }
}

//DIALOG
.mb-web-dialog {
  --height: auto;
  --width: auto;
  --border-radius: 20px;
  --box-shadow: none;
  .modal-wrapper {
    max-width: 100%;
    width: auto;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.mb-page-content-bg {
  width: 100%;
  height: 100%;
  .mb-page-content-grid {
    width: 100%;
    height: 100%;
  }
  .mb-page-content-row {
    width: 100%;
    height: 100%;
  }
  .mb-page-content-bg-card-container {
    width: 100%;
    height: 100%;
    padding-top: 50px;
    .mb-page-content-bg-image {
      &.login {
        width: 100%;
        height: 100%;
        background: url(../assets/login-img.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      &.reset {
        align-self: center;
        max-width: 415px;
        max-height: 351px;
        width: 100%;
        height: 100%;
        background: url(../assets/mb-reset-password-bg.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
    .mb-page-content-card-col {
      display: flex;
      flex-direction: row;
    }
  }
}

.mb-data-table-schedule-label {
  margin: 0px 2px;
  padding: 5px;
  background: rgba(87, 185, 198, 0.15);
  border-radius: 3px;

  &.big {
    padding: 15px;
  }
}
.mb-status-with-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  .mb-data-table-label {
    width: 100%;
  }
}
.mb-status-indicator {
  height: 8px;
  width: 8px;
  background-color: var(--ion-color-success);
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
  display: inline-block;
  &.not-available {
    background-color: var(--ion-color-medium);
  }
  &.archived {
    background-color: var(--ion-color-danger);
  }
}
.mb-status-indicator .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--ion-color-dark);
  color: var(--ion-color-white);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 10%;
  left: 50%;
}

.mb-status-indicator:hover .tooltiptext {
  visibility: visible;
}

.covid-risk-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--ion-color-success);
  max-width: 130px;
  width: 100%;
  border-radius: 4px;
  height: 30px;
  justify-content: center;
  margin-left: 10px;
  .covid-risk-icon {
    height: 19px;
    width: 24px;
    background: url("../assets/icons/covid-icon.svg") no-repeat center center;
    margin-right: 6px;
  }
}
