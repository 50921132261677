.mb-data-table-toolbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  .mb-data-table-title-search {
    width: 100%;
  }
  .mb-data-table-add-button {
    padding: 0px 10px;
    height: 38px;
    .mb-data-table-add-icon {
      padding-right: 5px;
    }
    .mb-data-table-button-label {
      padding-right: 8px;
    }
  }
}

.Component-horizontalScrollContainer-12 {
  .MuiTable-root {
    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          .MuiIconButton-root {
            border-radius: 0;
          }
        }
      }
    }
  }
}
