@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Open+Sans:wght@400;600;700&display=swap");
/* SCROLLBARD */

* {
  scroll-behavior: smooth;
}

.tab-container {
  .bord-white {
    border-bottom: 1px white !important;
    outline: white !important;
  }
  /* ion-icon {
  font-size: 30px;
} */

  item-native {
    background-color: red;
  }

  ion-item-list {
    background-color: transparent;
  }
  .full {
    width: 100%;
  }
  .shadow {
    box-shadow: -1px 3px 5px 0px rgba(61, 61, 61, 0.29) !important;
  }
  .w100 {
    width: 100px;
  }
  .w150 {
    width: 150px;
  }
  .w200 {
    width: 200px;
  }
  .w50 {
    width: 50px !important;
  }
  pt-100 {
    padding-top: 100px;
  }
  .h100 {
    height: 5em;
  }

  ion-content ion-toolbar {
    --background: translucent;
  }

  ion-card {
    min-width: 300px;
  }
  ion-col {
    min-width: 320px;
  }
  six-steps {
    padding: 0px 200px;
  }

  h1,
  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700 !important;
    font-size: 38px !important;
  }

  h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700 !important;
    font-size: 24px !important;
  }
  h4,
  h5,
  h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600 !important;
    font-size: 18px !important;
  }

  body {
    font-family: "Open Sans", sans-serif !important;
  }

  .bkg-trans {
    background-color: transparent !important;
  }

  .lime {
    background-color: #57c6aa !important;
  }

  .teal {
    background-color: #57b9c6;
    color: #57b9c6;
  }
  .dark-teal {
    background-color: #3b8eaa;
    color: #3b8eaa;
  }

  .teal-text {
    color: #57b9c6;
  }
  .marg {
    margin: auto;
  }
  .landing-dark-blue-text {
    color: #104c82;
  }

  .landing-dark-blue {
    background-color: #104c82 !important;
  }
  .bord-10 {
    border-radius: 10px;
  }
  .bord-20 {
    border-radius: 20px;
  }
  .bord-5 {
    border-radius: 5px;
  }
  .white-text {
    color: white;
  }
  .pb-0 {
    padding-bottom: 0px !important;
  }
  ion-button {
    font-family: "Montserrat", sans-serif;
  }

  /* MY MOD */

  .mymod {
    width: 100%;
  }

  modal-wrapper sc-ion-modal-md {
    width: 800px !important;
  }
}
